import { ArrowBackIosNew, ArrowCircleRightSharp } from '@mui/icons-material';
import { useContext, useEffect, useState } from "react";
import { CurrentPContext, MasterContext, MobiOrNotContext, OrdStageContext, OrderContext } from "../ContextApi/context";

import { useHistory } from "react-router-dom";
import ConnUrls from "../Connections/ConnectURL";
import MyDateTime from "../DTechComp/MyDateTime";
import J5ClientApprove from '../DTechComp/Pop_Fixed/J5ClientApprove';
import utilsRests from "../_Utils/utilsRests";
import OrderFunctions from "./OrderFunctions";
import myFunctions from '../myFunctions';
import { Helmet } from 'react-helmet';


const OrderTypeSelect = (props) => {



    const history = useHistory();
    const [order, setOrder] = useContext(OrderContext);
    const [currentPage, setCurrentPage] = useContext(CurrentPContext);
    const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);
    const [master, setMaster] = useContext(MasterContext);

    const [ordStage, setOrdStage] = useContext(OrdStageContext);

    const [pageDisp, setPageDisp] = useState();
    const [shHid, setShHid] = useState((props.showHide != null ? props.showHide : true))

    const [doDeliver, setDoDeliver] = useState();
    const [doTakeAway, setDoTakeAway] = useState();
    const [thePopUp, setThePopUp] = useState("");


    useEffect(() => {

        refreshpage();
    }, [])

    const refreshpage = async () => {

        props.scrollToTop(10);
        props.shoTopStrp(false)
        props.setShowbanners(false)
        props.showfotter(false)

        let bk = await setCurrPage()
        setDoTakeAway(bk.Take);
        setDoDeliver(bk.Deliver);


    }


    useEffect(() => {

        if (order.Orders.length <= 0) {
            goToBack();
        }
        else {

            let bk = setCurrPage()
            setDoTakeAway(bk.Take);
            setDoDeliver(bk.Deliver);
            // let termpDeliver =(doDeliver) ? doDeliver : bk.Deliver;
            // setPageDisp(setThePag(bk));
        }


    }, [props])



    const goToBack = () => {
        history.goBack();

        // if (document.referrer.indexOf(window.location.host) === -1) {
        // }
        // else {
        //      history.push('/'); }
    }


    const setCurrPage = async () => {

        let restPage = await utilsRests.getRestById(order.restId);

        setCurrentPage({ ...restPage, pageTYp: "withConPay" });
        if (!restPage.cardComCnt && restPage.gammaJ5) {
            setThePopUp(
                <J5ClientApprove okClick={() => setThePopUp()}
                    btnClick2={() => history.push(`/`)} />
            )
        }

        let Deliver = (restPage.Delivery.Doing && MyDateTime.checkIfBetweenDeliveryHours(restPage).openOrCLose)
        //בודק השהיית משלוחים
        Deliver = !restPage.closeDeliverDelay || restPage.closeDeliverDelay.delaydVal === "0" ? Deliver : false;

        let Take = restPage.TakeAway.Doing;
        //בודק השהיית איסופים
        Take = !restPage.closeDeliverDelay || restPage.closeTakeDelay.delaydVal === "0" ? Take : false;

        return (
            { Deliver, Take }
        )

    }

    const goTakeAway = () => {
        order.OrderType = "Take"
        order.OrderTimes = currentPage.TakeAway?.Time
        order.OrderDelPrice = 0;
        order.Address = currentPage.buissAddress;
        setOrdStage(3);
        setOrder({ ...order });

        history.push("/MyOrder");
    }

    const goDelivery = () => {
        order.OrderType = "Deliver"
        order.OrderTimes = currentPage.Delivery?.Time || 40;
        order.OrderDelPrice = currentPage.Delivery?.Price;
        order.Address = currentPage.buissAddress;
        setOrdStage(3);
        setOrder({ ...order });

        history.push("/MyOrder");
    }

    const backToRestMenu = () => {
        history.push(`/restaurants/${currentPage.pageUrlFix != null && currentPage.pageUrlFix != "" ?
            currentPage.pageUrlFix : currentPage._id}`)
    }

    const setThePag = (bk) => {

        let tempTakeAway = (doTakeAway) ? doTakeAway : bk.Take;
        let termpDeliver = (doDeliver) ? doDeliver : bk.Deliver;
        return (
            <div className={`OrderTypeSelectBlock ${shHid}`}>
                {(mobiOrNot) ?
                    <div className={"headOnFixC SecondFont bold big"}>
                        {currentPage.buissName}

                        <div className={`backSd`}>
                            <ArrowCircleRightSharp onClick={() => goToBack()} />
                        </div>
                    </div>
                    : ""}

                <div className={"ORDTHead bold huge"}>משלוח או איסוף עצמי?</div>

                {(tempTakeAway) ?
                    <div className={"ORDTYLine"} onClick={() => goTakeAway()}>
                        {OrderFunctions.takeAwayBlock(`זמן הכנה: ${currentPage.TakeAway?.Time} דק'`, currentPage.buissAddress)}
                        <div className={"goOrdType"}><ArrowBackIosNew /></div>
                    </div> : ""}

                {(termpDeliver && (parseInt(currentPage.Delivery?.Price) > 0)) ?
                    <div className={"ORDTYLine"} onClick={() => goDelivery()}>
                        {OrderFunctions.deliveryBlock(`זמן משלוח: ${currentPage.Delivery?.Time} דק'`,
                            `${parseInt(currentPage.Delivery?.Price) > 0 ?
                                parseInt(currentPage.Delivery?.Price) : ""}`)}
                        < div className={"goOrdType"}><ArrowBackIosNew /></div>
                    </div>
                    : ""}

            </div >
        )
    }

    return (
        <div>
            <div className={"imgblok"} style={{ backgroundImage: `url(${ConnUrls.servMediasUrl}${master.delorpickImg})` }}>
                {/* <img src={`${ConnUrls.servMediasUrl}${master.delorpickImg}`} /> */}
            </div>
            <div className={`OrderTypeSelectBlock ${shHid}`}>
                {/* {(screenWidth <= 800) ?
                    <div className={"headOnFixC SecondFont bold big"}>
                        {currentPage.buissName}

                        <div className={`backSd`}>
                            <ArrowCircleRightSharp onClick={() => goToBack()} />
                        </div>
                    </div>
                    : ""} */}

                <div className={"SecondFontRegular bold anakMeod centerText"}>סוג הזמנה</div>
                <div className={"FirstFont regular centerText"}>כבר מסיימים, בחר אופציה אחת</div>


                {(doDeliver && !master.blockDeliverys.block) ?
                    <div className={"ORDTYLine"} onClick={() => goDelivery()}>
                        {OrderFunctions.deliveryBlock(`זמן משלוח: ${currentPage.Delivery?.Time} דק'`,
                            `${parseInt(currentPage.Delivery?.Price) > 0 ?
                                parseInt(currentPage.Delivery?.Price) : "0"}`)}
                        <div className={"goOrdType"}><ArrowBackIosNew /></div>
                    </div>
                    : ""}

                {(doTakeAway && !master.blockPickUps.block) ?
                    <div className={"ORDTYLine"} onClick={() => goTakeAway()}>
                        {OrderFunctions.takeAwayBlock(`זמן הכנה: ${currentPage.TakeAway?.Time} דק'`, currentPage.buissAddress)}
                        <div className={"goOrdType"}><ArrowBackIosNew /></div>
                    </div> : ""}
            </div>
            {/* <div className={"backToRestMenu bold big smal centerText pointing"} onClick={() => backToRestMenu()}>חזור לתפריט</div> */}
            {thePopUp}
            {/* <Helmet
                meta={[
                    {
                        property: "og:url", content: `${ConnUrls.siteDomainNam}/TakeOrDel`
                    }
                ]}>
                <link rel="canonical" href={`${ConnUrls.siteDomainNam}/TakeOrDel`} />
            </Helmet> */}
        </div>
    );
}

export default OrderTypeSelect;
