// import { Route, Switch } from 'react-router';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {
  CurrentPContextProvider,
  MasterContextProvider,
  MobiOrNotContextProvider,
  OrdStageContextProvider,
  OrderContextProvider,
  UserContextProvider
} from './ContextApi/context';
import MasterHead from "./Master//MasterHeads";
import Master from './Master/Master';
import Sitemap from './web_pages/SiteMap/SiteMap';


function App() {
  return (
    <MobiOrNotContextProvider>
      <MasterContextProvider>
        <UserContextProvider>
          <OrderContextProvider>
            <CurrentPContextProvider>

              <OrdStageContextProvider>
                <Router>
                  <Switch>
                    <Route path={"/sitemap"}>
                      <Sitemap />
                    </Route>
                    {/* <Route path={"/sitemap.xml"}>
                    <Sitemap />
                  </Route> */}
                    <div className="webMain">
                      <MasterHead />

                      <Master />

                    </div>
                  </Switch>
                </Router>

              </OrdStageContextProvider>

            </CurrentPContextProvider>
          </OrderContextProvider>
        </UserContextProvider>
      </MasterContextProvider >
    </MobiOrNotContextProvider>
  );
}

export default App;
