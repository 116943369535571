import utils from "../_Utils/utilsMaster";
import { useContext, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { CurrentPContext, MasterContext, UserContext } from "../ContextApi/context";
import { AccountCircle, ArrowBack, Circle, Login, Logout, MenuOpenSharp, MenuSharp } from '@mui/icons-material';
import ArrowRight from '@mui/icons-material/ArrowRight';
import { useHistory } from "react-router-dom";
import { useSwipeable } from 'react-swipeable';
import TopBigSlides from "../DTechComp/TopBigSlides";
import ConnUrls from '../Connections/ConnectURL';
import PopUpClearComp from "../DTechComp/PopUpClearComp";
import LoginPage from "../UsersLogs/LoginPage";

const MobiMenu = (props) => {

  const [master, setMaster] = useContext(MasterContext);
  const [currentPage, setCurrentPage] = useContext(CurrentPContext);

  const [user, setUser, UserEffectFunction] = useContext(UserContext);

  const [mnuShow, setMnuShow] = useState(false);
  const [thePopUp, setThePopUp] = useState("");

  const [topSlides, setTopSlides] = useState();
  const history = useHistory();

  useEffect(() => {
    UserEffectFunction();
    setTopSlides(<TopBigSlides TopImages={master.mobiMenuTopImages} />)

  }, [])

  useEffect(() => {
    setMnuShow(props.mnuShow)
  }, [props.mnuShow])

  const loginUsr = () => {
    // history.push("/LoginPage");
    setThePopUp(
      <PopUpClearComp
        btnFStyle={"fatInBtn"}
        closePop={() => setThePopUp()}
        theInsideComp={
          <LoginPage theInsideComp={""}
            backFunc={true}
            closeMeFuc={() => setThePopUp()}
            shoTopStrp={(tf) => { }}
            scrollToTop={(stUp) => { }} />
        } />)

    setMnuShow(false);
  }

  const logOutUsr = () => {
    localStorage.removeItem('usrtoken');
    setUser();
    // UserEffectFunction();
    setMnuShow(false);
    // history.push("/");
  }


  const mobileFlUserClick = () => {
    if (
      user === null ||
      user._id === null ||
      user.firstName === ""
    ) {
      setMnuShow(!mnuShow)
    }
    else {
      history.push("/MyOrders")
    }
  }

  const swipME = useSwipeable({
    // onSwipedLeft: (eventData) => alert("User Swiped Left!", eventData),
    // onSwipedRight: (eventData) => alert("User Swiped Right!", eventData),
    // swipeDuration: 500,
    // preventScrollOnSwipe: true,
    // trackMouse: true
  });


  return (
    <div className={"mobiMnuDiv"}  {...swipME}>
      <div id="mobiFloatLeftID" onClick={() => setMnuShow(!mnuShow)} className={`mobiFloatLeft ${!mnuShow ? "" : "mobiFloatLShow"}`}>
        <MenuSharp className={"mobiArr"} sx={{}} />
      </div>

      <div id="mobiMnuID" className={`mobiMnu ${!mnuShow ? "mobMnuSh" : ""}`}>
        <div className={"mobiMnuInWrap"}>
          <div className={"topSlidesWrap"}>{topSlides}</div>
          <div className={"fatLIne"}>
            <div className={"userDet"}>

              <div className={"CloseMobiBtn pointing"}>
                <div className={"usrDispOnMenu"}>
                  <span className={"big bold"}>
                    <div id="mobiFloatLeftUsr" onClick={() => mobileFlUserClick()} className={`mobiFloatLeftUsr`}>
                      <AccountCircle className={"mobiArr"} sx={{}} />
                    </div>
                    <span> שלום </span>
                    {(user?.firstName != "null" && user?.firstName != "") ?
                      <span>{user?.firstName}</span> :
                      <span>אורח</span>}
                  </span>
                </div>
                <div className={"inOutOnMenu"}>
                  <div className={"inOutLogDiv"}>
                    {(user?._id != null) ?
                      <div className={"logOnDsk"}>
                        <Logout onClick={() => logOutUsr()} />
                        <span className="inOutBott fullWidth">התנתקות</span>
                      </div> :
                      <div className={"logOnDsk"}>
                        <Login onClick={() => loginUsr()} />
                        <span className="inOutBott fullWidth">התחברות</span>
                      </div>
                    }
                  </div>
                </div>
              </div>

              {
                (user?._id != null) ?
                  <div className={"MobiMenuME"}>
                    <div className="mobiMnuLnkItm1"
                      onClick={() => { history.push("/MyOrders"); setMnuShow(false) }}>

                      <Circle sx={{ color: 'var(--mainYellow)' }} className={"onRight circleOnMNU"} />
                      <div className={"onRight mobiMnuTxt spaceRight pointing"}>
                        ההזמנות שלך
                      </div>
                    </div>
                    <div className={"MobiMenuME"}>
                      <div className="mobiMnuLnkItm1"
                        onClick={() => { history.push("/MyDetails"); setMnuShow(false) }}>

                        <Circle sx={{ color: 'var(--mainYellow)' }} className={"onRight circleOnMNU"} />
                        <div className={"onRight mobiMnuTxt spaceRight pointing"}>
                          הפרטים שלך
                        </div>
                      </div>
                    </div>
                  </div>
                  : ""
              }
            </div>

          </div>
          <div className={"MobiMenuMainMnu spacePadBig"}>
            {
              master.mainMenu.map((item, index) => {
                return (
                  <div key={index} className="mobiMnuLnkItm2 pointing"
                    onClick={() => history.push(`/${item.lnk}`)}>
                    <div colSpan={"2"}>
                      {item.txt}
                    </div>
                  </div>
                )
              })
            }
          </div>
          <div className={"botmMnuLogoWrap"}>

            <div className={"botmMnuLogo"} onClick={() => { setMnuShow(!mnuShow); history.push("/") }}>
              <img className={"btmLgoImg"} src={`${ConnUrls.servMediasUrl}${master.mobiMenuBotLogo}`} alt={currentPage.altTags || currentPage.pageName || ""} />
            </div>
            <div className={"botmMnuLogoTxt"}>כל הזכויות שמורות ל-"אשדוד בתפריט"</div>
          </div>

        </div >
      </div>
      {thePopUp}
    </div>
  )

}

export default MobiMenu;
