

import { ArrowCircleDownSharp, ArrowCircleRightSharp, ArrowCircleUp, ContactsOutlined, LocalDining, ShoppingCartOutlined } from '@mui/icons-material';
import { useContext, useEffect, useState } from 'react';
import { CurrentPContext, MasterContext, OrderContext, UserContext } from '../ContextApi/context';
import Checkbox from '@mui/material/Checkbox';
import CircleChecked from '@mui/icons-material/CheckCircleOutline';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import Subject from '@mui/icons-material/Subject';
import Payment from '@mui/icons-material/Payment';
import Edit from '@mui/icons-material/Edit';
import EditOff from '@mui/icons-material/EditOff';



import TheMount from './TheMount';
import OrderFunctions from './OrderFunctions';
import { useHistory } from "react-router-dom";
import GoogleMapItem from '../DTechComp/GoogleMapItem';
import utilsRests from '../_Utils/utilsRests';
import Scroll from 'react-scroll';

var scroller = Scroll.scroller;

const ShopingCart = (props) => {
    const [master, setMaster] = useContext(MasterContext);
    const [order, setOrder] = useContext(OrderContext);
    const [currentPage, setCurrentPage] = useContext(CurrentPContext);
    const [user] = useContext(UserContext);
    const [thePopUp, setThePopUp] = useState("");

    const [yellowLineTxt, setYellowLineTxt] = useState("המשך לתשלום");

    const [payType, setPayType] = useState((order.payt != null) ? order.payt : "1");

    const [showContBtn, setShowContBtn] = useState(true);



    const history = useHistory();

    let popTiming = 0;


    useEffect(() => {
        props.shoTopStrp(false)
        window.scrollTo(0, 0);
        setCurrPage();
        order["payt"] = (order.payt != null) ? order.payt : "1";

        settingTheYellowline();

    }, [])



    const setCurrPage = async () => {

        if (currentPage.length <= 0) {
            let restPage = await utilsRests.getRestById(order.restId);
            setCurrentPage({ ...restPage, pageTYp: "withConPay" });
        }
    }

    useEffect(() => {
        settingTheYellowline();

    }, [order])

    //כאשר מחובר, מגדיר את הטקסט על הכפתור הצבהוב בהתאם לאופי ההזמנה
    const settingTheYellowline = () => {
        //איסוף
        if (order.OrderType == "Take") {
            //מזומן או אשראי? 
            //יש היפוך בגלל העברית אנגלית 
            //1 == אשראי
            setYellowLineTxt((order.payt == "1") ? "המשך לתשלום" : "בצע הזמנה");
        }
        //משלוח
        else {

            setYellowLineTxt("המשך הזמנה");
        }

    }

    //הסתר או הצג כמות של מנה מסויימת 
    const showHideMount = (elemId) => {

        let mountItm = document.getElementById(elemId);

        if (mountItm.classList.contains("slideMeUp")) {
            mountItm.classList.remove("slideMeUp");
        }
        else {
            mountItm.classList.add("slideMeUp");
        }
    }

    //הצג או הסתר הערות על המנה
    const showHideRemarks = (elemId) => {
        let Remarks = document.getElementById(`${elemId}_remarks`);
        let RemarksItm = document.getElementById(`${elemId}_remarksTxt`);
        let RemarksItmTxt = document.getElementById(`${elemId}_remarkstxLine`);

        if (Remarks.classList.contains("slideMeUp")) {
            Remarks.classList.remove("slideMeUp");
            RemarksItmTxt.classList.add("slideMeUp");
        }
        else {
            Remarks.classList.add("slideMeUp");
            RemarksItmTxt.classList.remove("slideMeUp");
        }
    }

    //הגדר את הטקסט על הערות המנה
    const setTheTxtRem = (index) => {

        let RemarksItm = document.getElementById(`${index}_remarksTxt`);
        let RemarksItmTxt = document.getElementById(`${index}_remarkstxLine`);
        RemarksItmTxt.textContent = RemarksItm.value;
        order.Orders[index].Remarks = RemarksItm.value;
        setOrder({ ...order });

        showHideRemarks(`${index}`);
    }


    //תוכן חלוקת המגש - בפיצריות
    const conten = (spec, item) => {
        if (spec.splitSelected != null) {
            let bk = "";
            switch (spec.splitSelected) {
                case "lHalf":
                    {
                        bk = "חצי שמאל";
                        break;
                    }
                case "rHalf":
                    {
                        bk = "חצי ימין";
                        break;
                    }
                case "rtQuart":
                    {
                        bk = "רבע ימין עליון";
                        break;
                    }
                case "rbQuart":
                    {
                        bk = "רבע ימין תחתון";
                        break;
                    }
                case "ltQuart":
                    {
                        bk = "רבע שמאל עליון";
                        break;
                    }
                case "lbQuart":
                    {
                        bk = "רבע שמאל תחתון";
                        break;
                    }
                default:
                    bk = "כל המגש";
                    break;

            }
            return (<span className={"regular"}>{bk}</span>)
        }
        else {
            return (<span className={"regular"}>{spec.itmsMount > 0 ? `X ${spec.itmsMount} ` : ""}</span>)
        }

    }




    ///המוצרים בהזמנה
    let ordersItems = order.Orders.map((item, index) => {

        return (

            <div key={index} className={"ordItem spaceButtomBig "}>

                <div className={"ordItmLine"}>
                    <Checkbox
                        onChange={() => showHideMount(index)}
                        icon={<Edit sx={{ color: 'black' }} />}
                        checkedIcon={<EditOff sx={{ color: 'black' }} />}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                        className={"chkBxChange rSideFirst"}
                    />
                    <span className={"bold rSideMiddle"}>
                        <table>
                            <tbody>
                                <tr>
                                    <td className={"tdmin10"}>
                                        <span className={"engFont ordItemMount"}>X&nbsp;</span><span className={"ordItemMount"}>{item.mount}&nbsp;</span>
                                    </td>
                                    <td>
                                        <span className={"ordItemMount"}> {item.productName}</span>
                                    </td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>


                    </span>
                    <span className={"bold lSideSmall"}>₪ {parseFloat(item.prodPrice) * parseInt(item.mount)}</span>
                </div>

                {/* edit mount */}
                <div id={index} className={"ordItmLine itemMount spacePadBig slideMeUp"}>
                    <span className={"bold itemMountRI"}>כמות:</span>
                    <span className={"bold itemMountLf"}><TheMount itemId={item.orderItemId} remFromOrder={() => { }} /></span>
                </div>

                {
                    (item.specials.length > 0) ?
                        item.specials.map((special, specialIndex) => {

                            let specCount = 0;
                            let specc = special.spec.map((spec, specIndex) => {

                                if (spec.addToPrice > -1) {
                                    specCount++;

                                    return (
                                        <div key={specIndex} className={"ordItmLine"}>
                                            <span className={"rSideFirst"}></span>
                                            <span className={"rSideMiddle"}>
                                                {conten(spec, item)}
                                                <span className={"regular"}> {spec.servType}</span>

                                            </span>
                                            <span className={"bold lSideSmall"}>
                                                {spec.addToPrice > 0 ? "₪ " + ((parseInt(spec.addToPrice) * parseInt(spec.itmsMount)) * parseInt(item.mount)) + " +" : ""}
                                            </span>
                                        </div>
                                    )
                                }
                            })
                            if (specCount > 0) {
                                return (
                                    <div key={specialIndex} className={"ordItmSpecLine spacePadSmall"}>
                                        <div className={"ordItmOptHead spacePadSmall bold"} >{special.optionName}</div>
                                        {specc}
                                    </div>
                                )
                            }


                        })
                        : ""
                }
                <div className={"ordItmLine remarksLine"} onClick={() => showHideRemarks(index)}>
                    <span className={"rSideFirst"}><Subject /></span>
                    <span className={"bold rSideMiddle"}>הערות למטבח</span>
                    <span className={"bold lSideSmall"}></span>
                </div>
                <div className={"ordItmLine remarksTxtLine"}>
                    <span className={"rSideFirst"}></span>
                    <span className={"rSideMiddle"} id={`${index}_remarkstxLine`}></span>
                    <span className={"lSideSmall"}></span>
                </div>
                <div id={`${index}_remarks`} className={"ordItmLine itemRemraks spacePadBig slideMeUp"}>
                    <textarea id={`${index}_remarksTxt`} className={"remarksTxt"}
                        placeholder={"ספר לנו איך אתה אוהב את האוכל, יש בעיה עם אלרגנים?"}>
                    </textarea>
                    <div className={"ButtonMe"} onClick={() => setTheTxtRem(index)}>שמור</div>
                </div>

            </div>


        )
    })


    //אופן התשלום השתנה ופה מעדכנים את ההזמנה
    const paytypChanched = (e) => {
        setPayType(e.target.value);
        let ordTemp = { ...order };
        ordTemp.payt = e.target.value;
        setOrder({ ...ordTemp });

    }

    //סיכום מחירים
    let TotPrices =
        <>
            <div className={"veryBig bold spacePad startNBlock centerText"}>כל המחירים בשקלים וכוללים מע"מ</div>
            <div className={"ordItmLine totalTxt"}>
                <div className={"bold totalTxtR"}>סכום ההזמנה</div>
                <div className={"bold totalTxtL"}>{OrderFunctions.getOrderPrice(order)} ₪ </div>
            </div>

            {(order.OrderType == "Deliver") ?
                <div className={"ordItmLine totalTxt"}>
                    <div className={"bold totalTxtR"}>משלוח</div>
                    <div className={"bold totalTxtL"}>{order.OrderDelPrice} ₪ </div>
                </div> : ""}

            {(order.orderKopon && order.orderKopon !== "")?
                <div className={"ordItmLine totalTxt"}>
                    <div className={"bold totalTxtR"}>קופון {order.orderKopon.Name}</div>
                    <div className={"bold totalTxtL"}>{order.orderKopon.koponPrice} ₪ </div>
                    </div> : ""}


            <div className={"ordItmLine totalTxt"}>
                <div className={"bold totalTxtR"}>סה"כ</div>
                <div className={"bold totalTxtL"}>{OrderFunctions.getOrderTotalPrice(order)} ₪ </div>
            </div>
        </>

    return (
        <>
            {order.Orders.length <= 0 ? <div className={"noItmes huge centerText"}>אין פריטים להזמנה.</div> :

                <div className={"TheOrderList shopCartPop"}>

                    <div className={"TheOrderListIn ordLstWrap"}>
                        <div className={"headOnFixC SecondFont bold big"}>
                            {currentPage.buissName}
                        </div>

                        <div className={"veryBig bold spaceRightVeryLong"}>ההזמנה שלך</div>
                        {ordersItems}
                        {TotPrices}

                    </div>

                    {/* <ContinueToPay theTxt={"המשך לתשלום"} nxUrl="" withPrice={true} /> */}
                    {/* <ContinueToPay theTxt={"המשך לתשלום"} withPrice={true} /> */}
                    {thePopUp}
                </div>
            }
        </>

    )


}
export default ShopingCart;

