import React, { useState } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CancelRounded } from '@mui/icons-material';

const PopUpPhoneCheckCode = (props) => {


    const [open, setOpen] = useState(true);
    const [inputTxt, setInputTxt] = useState("");
    const [err, setErr] = useState(props.showError ? true : false);
    const [erMsg, setErMsg] = useState(props.errorMsg);

    const saveMe = () => {
        if (inputTxt !== "") {
            props.okClick(inputTxt);
        }
        else {
            setErr(true);
        }
    };


    const handleClose = () => {
        props.cancelClick();
    };

    return (
        <Dialog open={open} className={`textSizeDiag popDiagBlock ${props.cssStyl ? props.cssStyl : ""
            }`}>
            <CancelRounded className={"inBlack popUpCloseBtn_Small pointing "} onClick={() => handleClose()} />
                
            {(props.topSlides != null) ?
                props.topSlides : ""
            }
            {
                (props.topImg != null) ?
                    <div className={"imgblok"} style={{ backgroundImage: `url(${props.topImg})` }}></div> : ""
            }
            <div className="roundedTops">
                <DialogTitle className={"theTitlePopSmall"}>
                    {props.Title.map((txtItm, index) => {
                        return (
                            <span key={index} >{txtItm}</span>
                        )
                    })}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {props.ContentText.map((txtItm, index) => {
                            return (
                                <span key={index} >{txtItm}<br /></span>
                            )
                        })}
                    </DialogContentText>

                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label={props.inLabel}
                        type="number"
                        fullWidth
                        variant="standard"
                        className="spaceTopBig inputPhonetx"
                        placeholder={`${props.placeholdTxtF || "מספר נייד"} `}
                        onChange={(e) => { setInputTxt(e.target.value); setErr(false) }}
                    />
                    <p onClick={() => props.recreateCode()} className={"spacePadBig  centerText pointing"}>שלח לי שוב</p>

                    {err ? <div style={{ color: 'red', fontWeight: 'bold' }}>{props.errorMsg}</div> : ""}

                </DialogContent>
                <DialogActions >
                    <div className={"fullRow"}>
                        <div className={"fullRow"}>
                            <button type="button" className="centerMe btn btn-success btnPopRegular btnYellow1 btnPoppadding15 pointing"
                                onClick={() => saveMe()}>אימות</button>
                        </div>
                        <br />
                        {erMsg && erMsg.includes("לא נמצא משתמש עם מספר הטלפון המבוקש") &&
                            <div className={"fullRow"}>
                                <button type="button"
                                    className="centerMe btn btn-success btnPopRegular btnYellow1 pointing"
                                    onClick={() => props.register()}>הרשם</button>
                            </div>
                        }
                    </div>

                </DialogActions>
            </div>
        </Dialog>

    );
}

export default PopUpPhoneCheckCode;