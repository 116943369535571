
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import ConnUrls from "../Connections/ConnectURL";
import { MasterContext, OrderContext } from "../ContextApi/context";
import BigPopUpMsg from "../DTechComp/BigPopUpMsg";
import OrderFunctions from "../OrderSettings/OrderFunctions";





const LogoComp = (props) => {

  const [master, setMaster] = useContext(MasterContext);
  const [order, setOrder] = useContext(OrderContext);
  const [thePopUp, setThePopUp] = useState("");

  const history = useHistory();


  const goHome = () => {
 
    if (order.Orders?.length > 0 &&
      order.Orders[0] != "") {
      setThePopUp(
        <BigPopUpMsg key={"homeLogo"}
          class={"oops"}
          imgSrc={`${ConnUrls.servMediasUrl}${master.oopsPopImg}`}
          Title={["יש הזמנה פעילה"]}
          ContentText={["יש דברים שהוזמנו, ",
            "במידה ותצאו ההזמנה לא תשמר."]}
          inLabel={""}
          okClick={() => setThePopUp()}
          BtnTxt={"המשך להזמין"}
          btnClick2={() => {
            sessionStorage.setItem("restPage", "");
            setOrder(OrderFunctions.clearOrd())
            history.push("/");
            setThePopUp();
          }}
          BtnTxt2={"צא בכל זאת"}
          errorMsg={""} />
      )
    }
    else {
      sessionStorage.setItem("restPage", "");
      history.push("/");
    }
  }

  return (
    <div className={"mobiLogoDiv pointing"}>
      <div onClick={() => goHome()} >
        <img src={props.logoUrl} alt="אשדוד בתפריט" />
      </div>
      {thePopUp}
    </div>
  )

}

export default LogoComp;
