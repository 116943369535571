import { ArrowCircleDownSharp, ArrowCircleRightSharp, ArrowCircleUp, CancelRounded, Sms } from "@mui/icons-material";
import { TextField } from "@mui/material"
import { useContext, useEffect, useState } from "react";
import { CurrentPContext, MasterContext, UserContext } from "../ContextApi/context";
import TopBigSlides from "../DTechComp/TopBigSlides";
import { useHistory } from "react-router-dom";
import InputDubleSet from "../DTechComp/InputDubleSet";
import utilsUsers from "../_Utils/utilsUsers";
import utilsLogInP from "../_Utils/utilsLogInP";
import PopUpPhoneConnect from "../DTechComp/PopUpPhoneConnect";
import utilsCodes from "../_Utils/utilsCodes";
import PopUpRegular from "../DTechComp/PopUpRegular";
import PopUpMsg from "../DTechComp/PopUpMsg";
import PopUpClearComp from "../DTechComp/PopUpClearComp";
import UserDetails from "./UserDetails";
import PopUpPhoneCheckCode from "../DTechComp/PopUpPhoneCheckCode";
import ConnUrls from "../Connections/ConnectURL";
import { Helmet } from "react-helmet";


const LoginPage = (props) => {



    const [master, setMaster] = useContext(MasterContext);
    const [user, setUser, UserEffectFunction] = useContext(UserContext);

    const [topSlides, setTopSlides] = useState();
    const [thePopUp, setThePopUp] = useState("");
    const [withNumber, setWithNumber] = useState(false);



    const history = useHistory();

    let popTiming = -1;

    useEffect(async () => {

        getHompData();
        props.scrollToTop();
        props.shoTopStrp(false);

        checkForPopUp();

    }, [])

    useEffect(async () => {
        if (props.withNumber || withNumber) {
            connectBySms();
        }

    }, [props.withNumber, withNumber])



    //בודק שהקישור הוא לא בקשת התחברות
    //אחרת ישירות מקפיץ פופאפ של התחברות ב
    //SMS
    const checkForPopUp = () => {
        let urStr = document.location.toString();

        if (urStr.includes("popLog")) {
            connectBySms();
        }
    }
    const goToBack = async () => {
        setThePopUp();
        try {
            props.closeMeFuc();
        }
        catch { }

        // let bkPage = sessionStorage.getItem('bkPage');

        await UserEffectFunction();

        // let bkPage = sessionStorage.getItem('bkPage');

        // if (bkPage && bkPage != "") {
        //     history.push(bkPage);
        // }
        // else {
        //     history.push('/');
        // }

        // if (document.referrer.indexOf(window.location.host) === -1) {
        // }

    }

    const getHompData = async () => {
        setTopSlides(<TopBigSlides TopImages={master.loginTopImages} />)
    }

    const regisetMe = () => {
        setThePopUp(
            <PopUpClearComp
                cssStyl={`onTopOfAll ${props.cssStyl ? props.cssStyl : ""}`}
                btnFStyle={"fatInBtn"}
                closePop={() => setThePopUp()}
                theInsideComp={
                    <UserDetails theInsideComp={""}
                        backFunc={true}
                        closeMeFuc={() => { setThePopUp(); props.closeMeFuc() }}
                        shoTopStrp={(tf) => { }}
                        scrollToTop={(stUp) => { }}
                        connectWithNumber={() => { setThePopUp(); setWithNumber(true) }}
                    />

                } />)
    }

    const connectByEmail = () => {
        popTiming++;
        setThePopUp(
            <InputDubleSet key={popTiming} Title={"התחברות"}
                ContentText={""} inLabel={"כתובת אימייל"} inLabel2={"סיסמא"}
                okClick={(connDetails) => connectUserByMail(connDetails)}
                cancelClick={() => setThePopUp()}
                inputTxt={""}
                inputPrc={""}
                errorMsg={"יש להזין מייל וסיסמא תקינים על מנת להתחבר"}
            />)
    }

    const connectUserByMail = async (connDetails) => {
        popTiming++;
        let details = {
            email: connDetails.inputTxt,
            password: connDetails.inputPrc,
        };

        let connectMe = await utilsLogInP.connectUsr(details);


        if (connectMe.errMsg != "" && connectMe.errMsg != undefined) {
            setThePopUp(
                <InputDubleSet key={popTiming} Title={"התחברות"}
                    ContentText={""} inLabel={"כתובת אימייל"} inLabel2={"סיסמא"}
                    okClick={(connDet) => connectUserByMail(connDet)}
                    cancelClick={() => setThePopUp()}
                    showError={true}
                    inputTxt={details.email}
                    inputPrc={details.password}
                    errorMsg={connectMe.errMsg}
                />)
        }
        else {
            setUser(connectMe);
            connectedMsg(connectMe);
        }
    }


    const connectBySms = () => {
        popTiming++;
        setThePopUp(
            <PopUpPhoneConnect key={popTiming} Title={["התחברות SMS"]}
                cssStyl={`onTopOfAll`}
                topSlides={<TopBigSlides TopImages={[{ img: master.connsectBySMSImg, txt: "" }]} />}

                ContentText={["על מנת להזדהות, יש להקליד את מספר הטלפון לקבלת קוד."]} inLabel={""}
                okClick={(phoneNum) => checkConCode(phoneNum)}
                cancelClick={() => setThePopUp()}
                recreateCode={() => connectBySms()}
                errorMsg={"יש להזין מספר טלפון תקין"}
            />)

    }

    const checkConCode = async (phoneNum) => {
        popTiming++;

        let codeReply = await utilsCodes.createCodeSms(phoneNum);
        console.log(codeReply.errormsg)
        if (codeReply.errormsg) {

            setThePopUp(
                <PopUpPhoneConnect key={popTiming} Title={["התחברות SMS"]}
                    cssStyl={`onTopOfAll`}
                    topSlides={<TopBigSlides TopImages={[{ img: master.connsectBySMSImg, txt: "" }]} />}

                    ContentText={["על מנת להזדהות, יש להקליד את מספר הטלפון לקבלת קוד."]} inLabel={""}
                    okClick={(phoneNum) => checkConCode(phoneNum)}
                    register={() => { regisetMe(); setThePopUp() }}
                    cancelClick={() => setThePopUp()}
                    recreateCode={() => connectBySms()}
                    showError={true}
                    errorMsg={codeReply.errormsg}
                />)
        }
        else {
            setThePopUp(
                <PopUpPhoneCheckCode key={popTiming} Title={["קוד SMS"]}
                    cssStyl={`onTopOfAll specialContactPop`}
                    // topSlides={<TopBigSlides TopImages={[{ img: master.codeSMSImg, txt: "" }]} />}
                    topSlides={null}
                    placeholdTxtF="קוד לבדיקה"
                    ContentText={["ברגעים אלו נשלח אליך קוד לטלפון", "נא להקיש את הקוד"]} inLabel={""}
                    okClick={(codeCheck) => checkForSMSConCode(codeCheck, codeReply.nId, phoneNum)}
                    cancelClick={() => setThePopUp()}
                    recreateCode={() => connectBySms()}
                    errorMsg={""}
                    showError={false}
                />
            )


        }

    }


    const checkForSMSConCode = async (codeCheck, nId, phoneNum) => {
        let connectMe = await utilsCodes.checkConnCode(nId, { codeCheck, phoneNum });

        if (connectMe.errMsg != "" && connectMe.errMsg != undefined) {
            popTiming++;
            setThePopUp(
                <PopUpPhoneCheckCode key={popTiming} Title={["קוד SMS"]}
                    cssStyl={`onTopOfAll`}
                    topSlides={<TopBigSlides TopImages={[{ img: master.codeSMSImg, txt: "" }]} />}
                    placeholdTxtF="קוד לבדיקה"
                    ContentText={["ברגעים אלו נשלח אליך קוד לטלפון", "נא להקיש את הקוד"]} inLabel={""}
                    okClick={(codeCheck) => checkForSMSConCode(codeCheck, nId, phoneNum)}
                    cancelClick={() => setThePopUp()}
                    recreateCode={() => connectBySms()}
                    showError={true}
                    errorMsg={connectMe.errMsg}
                />)
        }
        else {

            setUser(connectMe);
            connectedMsg(connectMe);
        }
    }

    const connectedMsg = (usrdet) => {
        popTiming++;
        setThePopUp(
            <PopUpMsg key={popTiming} Title={[` היי ${usrdet.firstName}`, " שמחים שחזרת אלינו!"]}
                nosnippet={true}
                cssStyl={"okPOP onTopOfAll"}
                topSlides={<TopBigSlides TopImages={[{ img: master.suceccufulyconnectedImg, txt: "" }]} />}
                ContentText={[]} inLabel={""}
                okClick={() => goToBack()}
                btn1Css={"btnYellow1 btnPoppadding15"}
                errorMsg={""} />
        );
        setTimeout(() => { goToBack() }, 5000)
    }

    // const continToPage = () => {
    //     if (props.senderP != null) {
    //         history.push(props.senderP)
    //     }
    //     else {
    //         goToBack()
    //     }
    // }

    return (
        <div className={"loginPage"}>
            <div className={"headOnFixC SecondFont bold big"}>
                התחברות
                <div className={`backSd`}>
                    <CancelRounded className={"inBlack popUpCloseBtn_Small pointing "} onClick={() => goToBack()} />
                    {/* <ArrowCircleRightSharp onClick={() => goToBack()} /> */}
                </div>
            </div>
            <br />
            <br />
            {topSlides}

            <div className={"firstMastSec"}>
                <div className={"insideFrame"}>
                    <br />

                    <div className={"bold huge"}>שלום לך!</div>
                    <div className={"bold veryBig"}>התחברות למערכת.</div>
                    <br />

                    {/* <div className={"grayText spaceButtom regular"}>בחר/י גישה מועדפת</div> */}
                    <div className={"connectBtn conClear"} onClick={() => connectBySms()}>
                        {/* <div className={"loginBtn"}><Sms className={"smsColor"} /></div> */}
                        {/* <span>התחברות באמצעות</span><span className={"english spaceRight"}>SMS</span> */}
                        <span>משתמש רשום</span>
                    </div>
                    {/* <br />
                    <div className={"connectBtn conAsh"} onClick={() => connectByEmail()}>
                        <div className={"loginBtn logInMyIcon"}><img src={"/Media/Master/mainLogo.png"} alt="" /></div>
                        <span>התחברות באמצעות כתובת מייל</span>
                    </div> */}
                    <br />
                    <br />
                    {/* <div className={"bold veryBig"}>פתיחת חשבון</div>
                    <div className={"grayText spaceButtomBig regular"}>זה לוקח פחות מדקה.</div> */}
                    <div className={"openAccountBtn bold big"} onClick={() => regisetMe()}>משתמש חדש</div>
                    <br />

                    {/* <div className={"connectBtn conGoog"}>
                        <div className={"loginBtn logInGoogleIcon"}><img src={"/Media/Master/Google.png"} alt="" /></div>
                        <span>כניסה דרך גוגל</span>
                    </div> */}
                </div></div>
            {thePopUp}

            <Helmet
                meta={[
                    {
                        property: "og:url", content: `${ConnUrls.siteDomainNam}/LoginPage`
                    }
                ]}>
                <link rel="canonical" href={`${ConnUrls.siteDomainNam}/LoginPage`} />
            </Helmet>
        </div>
    )

}

export default LoginPage