import { useEffect, useState, useContext, useRef } from "react";
import { CurrentPContext, UserContext } from "../../../ContextApi/context";
import DatePick2 from "./DatePick2";
import { format, isToday } from 'date-fns';
import MyDateTime from "../../../DTechComp/MyDateTime";
import TimesListSelections from "./TimesListSelections";
import DinnersNumbSelect from "./DinnersNumbSelect";
import utilsTblOrders from "../../../_Utils/utilsTblOrders";
import SpecialRequests from "./SpecialRequests";
import PopUpClearComp from "../../../DTechComp/PopUpClearComp";
import LoginPage from "../../../UsersLogs/LoginPage";
import ApprovedOrdr from "./ApprovedOrdr";
import PopUpTheComp from "../../../DTechComp/PopUpTheComp";
import Table_Order_Confirmd from "../../../DTechComp/Pop_Fixed/Table_Order_Confirmd";

const TblOrderReservation = (props) => {

    const [currentPage, setCurrentPage] = useContext(CurrentPContext);
    const [user, setUser] = useContext(UserContext);
    const [thePopUp, setThePopUp] = useState("");
    const [dispDate, setDispDate] = useState(false)
    const [dispTime, setDispTime] = useState(false)
    const [dispDinners, setDispDinners] = useState(true)
    const [dispTableOpts, setDispTableOpts] = useState(false)
    const [panelDisp, setPanelDisp] = useState("")

    const [tabelsLocations, setTabelsLocations] = useState("")
    const [specialsList, setSpecialsList] = useState("")
    const [fromOpening, setFromOpening] = useState("00:00")
    const [toClosing, setToClosing] = useState("00:00")

    const [tablesList, setTablesList] = useState([])

    const [dateToCome, setDateToCome] = useState(new Date())
    const [timeToCome, setTimeToCome] = useState("")
    const [dinners, setDinners] = useState(0)

    const [tabelsLocationsReq, setTabelsLocationsReq] = useState([])
    const [specialsListReq, setSpecialsListReq] = useState([])

    const [timesListApproved, setTimesListApproved] = useState([])
    const [loading, setLoading] = useState(true);

    const [errorsTxts, setErrorsTxts] = useState([]);

    const goToOrderMeRef = useRef();


    useEffect(() => {
        getTblsSpec();

    }, [])

    useEffect(() => {
        setSlectDiners();
    }, [tabelsLocations])


    useEffect(() => {
        setSelectdDinners(dinners);
    }, [dinners])

    useEffect(() => {


    }, [user])

    //מושך פרטי שולחנות של העסק
    const getTblsSpec = async () => {
        let tableSets = await utilsTblOrders.getTableSpecials(currentPage._id)
        setFromOpening(tableSets.fromOpening)
        setToClosing(tableSets.toClosing)
        setTablesList(tableSets.tables)
        setSpecialsList(tableSets.specialsList)
        setTabelsLocations(tableSets.tabelsLocations)

        setLoading(false)
    }

    //בחירת מספר סועדים
    const setSlectDiners = () => {

        let maxTableDinMax = -Infinity;
        let maxTableDinMaxItem = null;

        for (const item of tablesList) {
            const tableDinMaxValue = parseInt(item.tableDinMax, 10);
            if (tableDinMaxValue > maxTableDinMax) {
                maxTableDinMax = tableDinMaxValue;
                maxTableDinMaxItem = item;
            }
        }


        setDispDate(false);
        setDispTime(false);
        setDispDinners(true);
        setDispTableOpts(false);
        setPanelDisp(
            <DinnersNumbSelect
                settingFunc={(val) => setSelectdDinners(val)}
                dinnersSelected={dinners}
                Css={"inputsStyle pointing"}
                maxDinnersNumber={maxTableDinMax}
            />
        )
    }

    //נבחר מספר סועדים
    const setSelectdDinners = (val) => {
        setDinners(val);
        setSelecDate();
        // DispSelected("שולחן", dateToCome);
    }

    //בוחר תאריך הגעה
    const setSelecDate = () => {

        let unavalabledays = getUnavaildDays();

        setDispDate(true);
        setDispTime(false);
        setDispDinners(false);
        setDispTableOpts(false);

        setPanelDisp(
            <DatePick2
                drpArrCss={"drpArrowWhite"}
                wrpaStyl={"DtPickwrpaStyl"}
                lbl="ל"
                settingFunc={(val) => dateToComeChanged(val)}
                Css="inputsStyle pointing"
                selectedDate={dateToCome}
                noDatesAvailable={unavalabledays}
            />
        )
    }

    //נבחר תאריך להזמנה
    const dateToComeChanged = (val) => {
        setDateToCome(val);
        if (format(dateToCome, 'dd.MM.yyyy') !== format(val, 'dd.MM.yyyy')) {
            setTimeToCome("");
            setSelecHour(val, "");
        }
        else {
            setSelecHour(val, timeToCome);
        }

    }

    //בחירת שעת הגעה
    const setSelecHour = (dtCome, tmToCOme) => {

        let tms = getHoursApproveByDay(dtCome);
        setDispDate(false);
        setDispTime(true);
        setDispDinners(false);
        setDispTableOpts(false);
        setPanelDisp(
            <TimesListSelections
                settingFunc={(val) => timeToComeChanged(val)}
                hoursList={tms}
                Css="inputsStyle pointing"
                timeSelected={tmToCOme}

            />
        )
    }

    //נבחרה שעה להזמנה
    const timeToComeChanged = (val) => {
        setTimeToCome(val);
        setSelecSpec();

    }


    //בחירת העדפות שולחן
    const setSelecSpec = () => {
        setDispDate(false);
        setDispTime(false);
        setDispDinners(false);
        setDispTableOpts(true);
        setPanelDisp(
            <SpecialRequests
                settingFunc={(val) => setRequests(val)}
                tabelsLocations={tabelsLocations}
                specialsList={specialsList}
                tabelsLocationsReq={tabelsLocationsReq}
                specialsListReq={specialsListReq}

                Css="inputsStyle pointing"
            />
        )
    }

    //נבחרו מאפייני שולחן
    const setRequests = (respo) => {
        setTabelsLocationsReq(respo.tabelsLocationsReq)
        setSpecialsListReq(respo.specialsListReq)
    }

    //ימים שהמסעדה לא פתוחה
    const getUnavaildDays = () => {
        let closedDays = currentPage.OpeningHours
            .map((dayItm, i) => MyDateTime.getTime(dayItm.Open) === "00:00:00" &&
                MyDateTime.getTime(dayItm.Close) === "00:00:00"
                ? MyDateTime.getDayOfWeekByDayNum(i + 1)
                : null)
            .filter(day => day !== null);

        return (closedDays)

    }

    //מושך שעות מאושרות להזמנה על ידי תאריך הבקשה
    const getHoursApproveByDay = (val) => {

        let opHrs = currentPage.OpeningHours.filter((curDt, i) =>
            curDt.Day === MyDateTime.getDayOfWeek(val));

        if (opHrs.length === 0) {
            return []; // Handle case when opening hours for the current day are not found
        }



        const intervalInMinutes = 15;

        let theFullTmLstCreated = [];

        // Calculate previous day
        const previousDay = MyDateTime.getYesterday(val, 1);

        // Find opening hours for the previous day
        let prevOpHrs = currentPage.OpeningHours.find((curDt) =>
            curDt.Day === previousDay);

        if (prevOpHrs) {
            const prevCloseTime = MyDateTime.getTime(prevOpHrs.Close);
            if (prevCloseTime > "00:00:00" && prevCloseTime <= "05:00:00") { // Check if previous day's close time is after midnight
                const startTimList = generateTimeList("00:00:00", prevCloseTime, intervalInMinutes, fromOpening, toClosing);
                theFullTmLstCreated = theFullTmLstCreated.concat(startTimList);
            }
        }


        const openTime = MyDateTime.getTime(opHrs[0].Open);
        let closeTime = MyDateTime.getTime(opHrs[0].Close);

        
        let toclsTime = (closeTime >= "00:00:00" && closeTime <= "05:00:00") ? "00:00:00" : toClosing;
        closeTime = (closeTime >= "00:00:00" && closeTime <= "05:00:00") ? "00:00:00" : closeTime;
        closeTime = (closeTime === "00:00:00") ? "23:59:59" : closeTime;

      
        // Generate time list for the current day
        let currtDayLst = generateTimeList(openTime, closeTime, intervalInMinutes, fromOpening, toClosing);
        // Merge time lists for the previous day and the current day
        theFullTmLstCreated = theFullTmLstCreated.concat(currtDayLst);
        return theFullTmLstCreated;

    }

    //יוצר רשימת שעות אפשריות לבחירה לפי שעות פתיחה של היום הנבחר
    const generateTimeList = (openTime, closeTime, intervalInMinutes, fromOpening, fromClosing) => {
        const timeList = [];
        let currentTime = new Date();
        const closeTimeObj = new Date();

        const [hours, minutes, seconds] = openTime.split(':');
        const [Clhours, Clminutes, Clseconds] = closeTime.split(':');

        const [fromOpeningHours, fromOpeningMinutes] = fromOpening.split(':');
        const [fromClosingHours, fromClosingMinutes] = fromClosing.split(':');

        // Subtract fromOpening time
        currentTime.setHours(parseInt(hours, 10) + parseInt(fromOpeningHours, 10));
        currentTime.setMinutes(parseInt(minutes, 10) + parseInt(fromOpeningMinutes, 10));
        currentTime.setSeconds(seconds);

        closeTimeObj.setHours(parseInt(Clhours, 10) - parseInt(fromClosingHours, 10));
        closeTimeObj.setMinutes(parseInt(Clminutes, 10) - parseInt(fromClosingMinutes, 10));
        closeTimeObj.setSeconds(Clseconds);

        while (currentTime <= closeTimeObj) {
            timeList.push(currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }));

            // Clone the currentTime object before modifying it
            const newTime = new Date(currentTime);

            // Update the cloned object
            newTime.setMinutes(newTime.getMinutes() + intervalInMinutes);

            // Update currentTime with the cloned object
            currentTime = newTime;
        }

        return timeList;
    };

    const goToOrderMe = () => {
        let err = [];

        if (timeToCome === "") {
            err.push("יש לבחור שעה להזמנה")
        }
        if (dinners === 0) {
            err.push("יש לציין מספר סועדים להזמנה")
        }
        if (err.length > 0)
            setErrorsTxts(err)
        else {
            if (user?._id != null) {

                let itm = {
                    buisId: currentPage._id,
                    buissName: currentPage.buissName,
                    dinners,
                    timeToCome,
                    dateToCome,
                    clientName: user.firstName + " " + user.lastName,
                    clientPhone: user.phone,
                    remarks: "",
                    tabelsLocationsReq: tabelsLocationsReq,
                    specialsListReq: specialsListReq

                }

                setThePopUp(
                    <PopUpTheComp
                        cssStyl={"onTopOfAll OrderTblPop"}
                        btnFStyle={"fatInBtn"}
                        closePop={() => { setThePopUp() }}
                        Title={[""]}
                        theInsideComp={
                            <ApprovedOrdr
                                tblOrdrItem={itm}
                                closeMeFuc={() => setThePopUp()}
                                cancelClick={() => setThePopUp()}
                                okClick={(itmBk) => ordrGo(itmBk)}

                            />}
                    />
                )
            }
            else {
                loginFirst();
            }

        }
    }

    //הכל מאושר, הזמן את השולחן
    const ordrGo = async (itm) => {
        setThePopUp();
        let ordBk = await utilsTblOrders.orderMyTable(itm);

        try {
            if (ordBk.msg === "approved") {
                setThePopUp(
                    <Table_Order_Confirmd
                        tblOrderNUM={ordBk.orderNumber}
                        okClick={() => { setThePopUp(); props.closeMe() }}
                    />)
            }
        }
        catch {

        }
    }

    const loginFirst = () => {
        setThePopUp(

            <PopUpClearComp
                cssStyl={"onTopOfAll"}
                btnFStyle={"fatInBtn"}
                closePop={() => { showClntDetails() }}
                theInsideComp={
                    <LoginPage theInsideComp={""}
                        backFunc={true}
                        closeMeFuc={() => showClntDetails()}
                        shoTopStrp={(tf) => { }}
                        scrollToTop={(stUp) => { }}
                    />
                } />)
    }

    const showClntDetails = () => {
        setThePopUp();
        if (goToOrderMeRef.current) {
            goToOrderMeRef.current.click();
        }
    }

    return (
        <div className="buisReservationPop">
            {
                !loading && <>

                    <div className={"fullRow overflowHid spaceButtomBig buisReservationPopBtns"}>
                        <h3 style={{ color: "black", textAlign: "center" }} className={"noMargin"}>הזמנת מקום במסעדה</h3>
                        <div className={"reservationBtWrap onRight"}>
                            <div onClick={() => setSlectDiners()}
                                className={`reservationBigBtn ${dispDinners ? "selectedINColor" : ""} ${(dinners !== 0) ? "smalPad" : ""} pointing`}>
                                {dinners === 0 ? "סועדים" : <><p>{dinners}</p><p>סועדים</p></>}
                            </div>
                        </div>

                        <div className={"reservationBtWrap onRight"}>
                            <div onClick={() => setSelecDate()}
                                className={`reservationBigBtn 
                        ${dispDate ? "selectedINColor" : ""} ${!isToday(dateToCome) ? "smalPad" : ""} pointing`}>
                                {isToday(dateToCome) ? "היום" : <><p>לתאריך</p><p> {format(dateToCome, 'dd.MM.yyyy')}</p></>}

                            </div>
                        </div>
                        <div className={"reservationBtWrap onRight"}>
                            <div onClick={() => setSelecHour(dateToCome, timeToCome)}
                                className={`reservationBigBtn ${dispTime ? "selectedINColor" : ""} ${(timeToCome !== "") ? "smalPad" : ""} pointing`}>
                                {timeToCome === "" ? "שעה" : <><p>בשעה</p><p>{timeToCome}</p></>}
                            </div>
                        </div>

                        <div className={"reservationBtWrap onRight"}>
                            <div onClick={() => setSelecSpec()}
                                className={`reservationBigBtn ${dispTableOpts ? "selectedINColor" : ""} pointing`}>העדפות</div>
                        </div>
                    </div>

                    <div className="reservationDispPanel">
                        {panelDisp}
                    </div>
                    <div className="fullRow margTopBig resrvationOrdBtnWrap">
                        <div ref={goToOrderMeRef}
                            onClick={() => goToOrderMe()} className="resrvationOrdMeBtn resrvationOrdMeBtn_in pointing">
                            הזמן
                        </div>
                        {errorsTxts.length > 0 &&
                            <div className={"spaceTopBig"}>
                                {errorsTxts.map((it, indx) => {
                                    return (
                                        <p key={indx} className={"fullRow overflowHid errorLine centerText MbigFont bold InRed"}>{it}</p>
                                    )
                                })}
                            </div>
                        }
                    </div>
                    {thePopUp}
                </>
            }
        </div >
    )
}

export default TblOrderReservation;
