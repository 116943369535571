import { DirectionsBike, FiberManualRecord } from '@mui/icons-material';
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CurrentPContext, MasterContext, MobiOrNotContext } from "../../ContextApi/context";
import utilsRests from "../../_Utils/utilsRests";

import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import ConnUrls from "../../Connections/ConnectURL";
import MyDateTime from "../../DTechComp/MyDateTime";
import myFunctions from "../../myFunctions";

const MainNowOpen = (props) => {
    const [master] = useContext(MasterContext);

    const [currentPage, setCurrentPage] = useContext(CurrentPContext);
    const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);

    const [currentP, setCurrentP] = useState(props.TheList);
    const [mainNowOpen, setMainNowOpen] = useState([]);


    useEffect(() => {
        getMRsts();

    }, [])


    const getMRsts = async () => {

        let allList = await utilsRests.getRestNowOpen({ maxItems: 150 });
        setMainNowOpen(allList);
        //אים אין מסעדות זמינות כרגע, מעדכן את דף הבית ומסתיר את החלק של זמינות עכשיו
        if (allList.length < 1) { props.noOpenRests(false); }
    }

    return (
        <div className={"FourMainLnksDiv"}>
            <Swiper
                spaceBetween={10}
                slidesPerView={mobiOrNot ? 3 : 6}
                loop={false}
                // onSlideChange={() => console.log('slide change')}
                freeMode={true}
                loopFillGroupWithBlank={false}

            // onSwiper={(swiper) => console.log(swiper)}
            >
                {
                    mainNowOpen.map((item, index) => {
                        if (item != null) {
                            let timing = (item.Delivery?.Time) ? item.Delivery.Time : (item.TakeAway?.Time) ? item.TakeAway.Time : 30;
                            let priceing = (item.Delivery?.Price) ? item.Delivery.Price : (item.TakeAway?.Price) ? item.TakeAway.Price : 20;
                            let opHours = MyDateTime.checkIfBetweenHours(item);

                            let stickyShow = (item.buissIsOnline != null && item.buissIsOnline) ? true : false;
                            // (!master.blockDeliverys ||
                            //     !master.blockPickUps) ?
                            //     item.Delivery?.Doing ||
                            //         item.TakeAway?.Doing ?
                            //         true : false
                            //     : false;

                            let notGettinOrders = myFunctions.notGettinOrders(master, opHours, item);



                            return (
                                <SwiperSlide key={index} className={mobiOrNot ? "favBtn" : "NotfavBtn"}>
                                    <Link to={`/restaurants/${item.pageUrlFix != null && item.pageUrlFix != "" ? item.pageUrlFix : item._id}`}>
                                        {stickyShow ? <>
                                            <div className={"stiickeyOnlnBlk"} style={{
                                                left: "5px",
                                                top: "2px",
                                                backgroundImage: `url(${ConnUrls.servMediasUrl}Uploads/MASTER/onlne.png)`,
                                                backgroundPosition: `center`,
                                                backgroundSize: `85%`,
                                                backgroundRepeat: 'no-repeat'
                                            }}
                                            >
                                            </div>

                                        </>
                                            : ""}

                                        <div className={"imgWrap animate__animated pulseME"}>
                                            {(notGettinOrders != "") ? 
                                            <div  data-nosnippet className={"no-render imgWrapDivClose imgWrapDivCloseSmall"}><span>{notGettinOrders}</span></div> : ""}

                                            <div className={"imgDiv mainFavImgDiv"} style={{ backgroundImage: `url("${ConnUrls.servMediasUrl}${item.buissMainImg}")` }} >

                                            </div>
                                            <div className={"botBlock"}>
                                                <div className={"pgBigTxt bold regular"}>
                                                    <span className={`specDot ${!opHours.openOrCLose ? "specDotClose" : ""}`}><FiberManualRecord /></span>
                                                    {item.buissName}
                                                </div>
                                                <div className={"pgSmallTxt mediumst"}>
                                                    <p>{item.buissKitchType}</p>
                                                </div>

                                                <div className={"pgSmallTxt mediumst"}>
                                                    <div className={"theImgwrap"} >
                                                        <div className={"theImg"} style={{ backgroundImage: `url(${ConnUrls.servMediasUrl}PermanentImages/tustus.png")` }}></div>
                                                    </div>



                                                    <div className={"timeTxt"}>

                                                        <div className={"theImg "} >
                                                            <DirectionsBike />
                                                            &nbsp;&nbsp;
                                                            {/* {priceing} ₪  &nbsp;&nbsp; */}
                                                            {(parseInt(timing) + 10) +
                                                                " - " + parseInt(timing)} דק'
                                                        </div>

                                                    </div>
                                                </div>


                                            </div>
                                        </div>


                                    </Link>
                                </SwiperSlide>
                            )
                        }
                    })
                }
                {mobiOrNot ?
                    <>
                        {[...Array(parseInt(mainNowOpen.length / 9) + 1)].map((_, i) => (
                            <SwiperSlide key={mainNowOpen.length + i + 1} className="favBtn spacerfavBtn">
                                <Link to="">
                                    <div className="catBtnMasterWrap">
                                        <img className="prodImage" src={""} alt={currentPage.altTags || currentPage.pageName || ""} />
                                    </div>
                                    <div className="fourMastBtn pointing">{/* Your content here */}</div>
                                </Link>
                            </SwiperSlide>
                        ))}
                    </>
                    :
                    <>
                        {[...Array(parseInt(mainNowOpen.length / 18)+ 1 )].map((_, i) => (
                            <SwiperSlide key={mainNowOpen.length + i + 1} className="favBtn spacerfavBtn">
                                <Link to="">
                                    <div className="catBtnMasterWrap">
                                        <img className="prodImage" src={""} alt={currentPage.altTags || currentPage.pageName || ""} />
                                    </div>
                                    <div className="fourMastBtn pointing">{/* Your content here */}</div>
                                </Link>
                            </SwiperSlide>
                        ))}
                    </>
                }
            </Swiper>
        </div >
    )
}

export default MainNowOpen;