import { ArrowCircleRightSharp } from "@mui/icons-material";
import { Checkbox } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { MasterContext, MobiOrNotContext, UserContext } from "../ContextApi/context";
import PopUpMsg from "../DTechComp/PopUpMsg";
import PopUpRegular from "../DTechComp/PopUpRegular";
import utilsCodes from "../_Utils/utilsCodes";
import myFunctions from "../myFunctions";
import PopUpPhoneCheckCode from "../DTechComp/PopUpPhoneCheckCode";
import TopBigSlides from "../DTechComp/TopBigSlides";
import { Helmet } from "react-helmet";
import ConnUrls from "../Connections/ConnectURL";


const UserDetails = (props) => {
   

    const [master, setMaster] = useContext(MasterContext);
    const [user, setUser, UserEffectFunction] = useContext(UserContext);
    const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [connectGO, setConnectGO] = useState(true);


    const [readIt, setReadIt] = useState(false);
    const [thePopUp, setThePopUp] = useState("");


    const history = useHistory();
    // const [topSlides, setTopSlides] = useState();

    const [theErrors, setTheErrors] = useState("");
    const [errorsTxts, setErrorsTxts] = useState("");


    let jumcodeChek = 0;
    let tryLoop = -1;

    useEffect(() => {

        props.scrollToTop();
        props.shoTopStrp(false)
        // getToPSldData();
    }, [])

    const saveUser = () => {

        let stopMe = false;
        let thEr = "";
        let errs = [];
        if (firstName.trim().length <= 1) {
            thEr = "firstName";
        }
        if (lastName.trim().length <= 1) {
            thEr += ",lastName";
        }
        if (!validPhone()) {
            thEr += ",phone";
            errs = (errs == "") ? "יש להזין מספר טלפון תקין" : errs;

        }
        if (!validEmail()) {
            thEr += ",email";
            errs = (errs == "") ? "יש להזין כתובת מייל חוקית" : errs;
        }
        // if (!validPassword()) {
        //     thEr += ",password";
        //     errs = (errs == "") ? "יש להזין סיסמא חזקה יותר.\n על הסיסמא להכיל מינמום 6 תווים, אות גדולה באנגלית, אות קטנה באנגלית ותו מיוחד ( לדוגמא: ! או $) " : errs;
        // }
        if (!readIt) {
            thEr += ",readIt";
            errs = (errs == "") ? "יש לאשר את קריאת התקנון" : errs;
        }

        if (thEr == "") {
            createUserApply();
        }

        setTheErrors(thEr)
        setErrorsTxts(errs)

    }

    const createUserApply = async () => {
        tryLoop++;

        let newUsr = { firstName, lastName, phone, email, password, readIt, tryLoop }
        let codeReply = await utilsCodes.createCodeNuser(newUsr);

        if (codeReply.errormsg != null) {

            if (codeReply.errormsg.includes("מספר הטלפון המוזן כבר קיים במערכת")) {
                setConnectGO(true)
            }
            else { setConnectGO(false) }
            setErrorsTxts(codeReply.errormsg);
            setThePopUp();
            if (codeReply.loopUp == true) setPhone("");


        }
        else {

            jumcodeChek++;
            setThePopUp(
                <PopUpPhoneCheckCode key={jumcodeChek} Title={["אימות פרטים"]}
                    cssStyl={`onTopOfAll`}
                    topSlides={<TopBigSlides TopImages={[{ img: master.codeSMSImg, txt: "" }]} />}
                    placeholdTxtF="קוד לבדיקה"
                    ContentText={["ברגעים אלו נשלח אליך קוד לטלפון", "נא להקיש את הקוד"]}
                    inLabel={""}
                    okClick={(codeCheck) => checkForCode(codeCheck, codeReply.nId)}
                    cancelClick={() => setThePopUp()}
                    recreateCode={() => createUserApply()}
                    errorMsg={""}
                />
            )
        }
    }

    const checkForCode = async (codeCheck, nId) => {
        let newUsr = { firstName, lastName, phone, email, password, readIt, tryLoop }
        let codeReply = await utilsCodes.checkCode(nId, { codeCheck, newUsr });
        jumcodeChek++;

        if (codeReply.errMsg !== "Created") {
            setThePopUp(
                <PopUpPhoneCheckCode key={jumcodeChek} Title={["אימות פרטים"]}
                    cssStyl={`onTopOfAll`}
                    topSlides={<TopBigSlides TopImages={[{ img: master.codeSMSImg, txt: "" }]} />}
                    placeholdTxtF="קוד לבדיקה"
                    ContentText={["ברגעים אלו נשלח אליך קוד לטלפון", "נא להקיש את הקוד"]}
                    inLabel={""}
                    okClick={(codeCheck) => checkForCode(codeCheck, codeReply.nId)}
                    cancelClick={() => setThePopUp()}
                    recreateCode={() => createUserApply()}
                    errorMsg={codeReply.errMsg}
                />
            )
        }
        else {
            localStorage.setItem("usrtoken", codeReply.token);

            let bk = await myFunctions.connCheck();
            if (bk != null) {

                setUser(bk.user);

            }
            else {

                let user = {
                    firstName: "",
                    lastName: "",
                    email: "",
                    rest: "",
                }
                setUser(user)
            }

            setThePopUp(
                <PopUpMsg key={jumcodeChek} Title={["ברוכים הבאים"]}
                nosnippet={true}
                    cssStyl={"okPOP onTopOfAll"}
                    topSlides={<TopBigSlides TopImages={[{ img: master.welcomeImg, txt: "" }]} />}
                    ContentText={[`היי ${firstName}`, "אנחנו ממש שמחים שהצטרפת אלינו!"]}
                    inLabel={""}
                    okClick={() => goToBack()}
                    btn1Css={"btnYellow1 btnPoppadding15"}
                    errorMsg={""} />

            );

            // setUser(codeReply);


        }
    }

    const validPhone = () => {
        if (phone !== "") {
            var clearPhone = phone.replace('-', '');
            var pattern = new RegExp(/^[0-9\b]+$/);
            if (!pattern.test(clearPhone)) {
                return false
            } else if (clearPhone.length != 10 && clearPhone.length != 9) {
                return false
            }
            else {
                return true;
            }
        }
    }
    const validEmail = () => {
        if (email !== "") {
            var pattern = new RegExp(/^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i);
            if (!pattern.test(email)) {
                return false
            }
            else {
                return true;
            }
        }
    }

    const validPassword = () => {
        if (password !== "") {
            let pattern = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{6,}))');
            if (!pattern.test(password)) {
                return false
            }
            else {
                return true;
            }
        }
    }
    const goToBack = async () => {
        setThePopUp();
        try {
            props.closeMeFuc();
        }
        catch { }

        // let bkPage = sessionStorage.getItem('bkPage');

        await UserEffectFunction();
        // if (bkPage && bkPage != "") {

        //     history.push(bkPage);
        // }
        // else {
        //     history.push('/');
        // }


        // if (props.backFunc) {
        //     props.closeMeFuc();
        // }
        // else {
        //     history.push("/");
        // }
    }

    return (
        <div className={"loginPage"}>
            <div className={"headOnFixC SecondFont bold big"}>
                הרשמה
                <div className={`backSd`}>
                    <ArrowCircleRightSharp onClick={() => goToBack()} />
                </div>
            </div>
            {(!mobiOrNot) ? <><br /><br /><br /><br /></> : ""}

            {/* {topSlides} */}

            <div className={"firstMastSec"}>
                <div className={"insideFrame"}>


                    <div className={"bold huge"}>הפרטים שלך</div>
                    <br />
                    {
                        (theErrors != "") ?
                            <div className={"errorLine"}>
                                {(!theErrors.includes("firstName") &&
                                    !theErrors.includes("lastName") &&
                                    errorsTxts != "") ?
                                    <p className={"vsmall bold"}>- {errorsTxts}</p> :
                                    <p className={"big bold"}>{"כל התאים המסומנים הינם חובה"}</p>}

                            </div>
                            : (errorsTxts != "") ?
                                <div className={"errorLine fullRow overflowHid spacePadBig"}>
                                    <p className={"vsmall bold onRight"}>- {errorsTxts}</p>
                                    {(connectGO) ?
                                        <div className="connectGo pointing" onClick={() => props.connectWithNumber()}>התחבר עם המספר</div> : ""}
                                </div> : ""
                    }


                    {/* <div className={"bold regular"}>על מנת להמשיך יש צורך להתחבר</div> */}
                    <div className={`usrDet details ${(theErrors.includes("firstName") ? "errorMe" : "")}`} >
                        <input type={"text"} className={"connInput"} value={firstName} placeholder={"שם פרטי"} onChange={(e) => setFirstName(e.target.value)} />
                    </div>
                    <br />
                    <div className={`usrDet details ${(theErrors.includes("lastName") ? "errorMe" : "")}`} >
                        <input type={"text"} className={"connInput"} value={lastName} placeholder={"שם משפחה"} onChange={(e) => setLastName(e.target.value)} />
                    </div>
                    <br />
                    <div className={`usrDet details ${(theErrors.includes("phone") ? "errorMe" : "")}`} >
                        <input type={"text"} className={"connInput"} value={phone} placeholder={"טלפון נייד (לקבלת SMS)"} onChange={(e) => setPhone(e.target.value)} />
                    </div>
                    <br />
                    <div className={`usrDet details ${(theErrors.includes("email") ? "errorMe" : "")}`} >
                        <input type={"text"} className={"connInput"} value={email} placeholder={"אימייל"} onChange={(e) => setEmail(e.target.value)} autoComplete="off" />
                    </div>
                    <br />
                    <div className={`usrDetChk  details ${(theErrors.includes("readIt") ? "errorMe" : "")}`} >
                        <Checkbox checked={readIt} onChange={() => setReadIt(readIt ? false : true)} className="chkBx" />
                        <div className={"readItTxt"}><span>קראתי ואני מסכים/ה לתנאי </span><Link className={"readItLink"} to={"/TAkanon"}>התקנון</Link></div>
                    </div>
                    <br />

                    <div className={"details pointing"}>
                        <div className={"bold big ConDetailsBtn"} onClick={() => saveUser()}>הרשם</div>
                        {/* <input className={"bold big ConDetailsBtn"} type="submit" value="הרשם" /> */}
                    </div>


                </div>

            </div>
            {thePopUp}
            <Helmet
                meta={[
                    {
                        property: "og:url", content: `${ConnUrls.siteDomainNam}/UserDetails`
                    }
                ]}>
                <link rel="canonical" href={`${ConnUrls.siteDomainNam}/UserDetails`} />
            </Helmet>
        </div >
    )

}

export default UserDetails