import master from "../_ApiConn/apiMaster";


const getMaster = async () => {
    
    let dFromF = await master.getMaster();
    return (dFromF.data[0]);
}


export default {
    getMaster
};