import hPageUtils from "../../_Utils/utilsHomePage";

import { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import ConnUrls from "../../Connections/ConnectURL";
import { CurrentPContext, MasterContext, MobiOrNotContext, OrderContext } from "../../ContextApi/context";
import SearchComp from "../../DTechComp/Search";
import CatsLnksComp from "../../Master/CatsLnksComp";
import OrderFunctions from "../../OrderSettings/OrderFunctions";
import utilsRests from "../../_Utils/utilsRests";
import myFunctions from "../../myFunctions";
import HomePTopSlides from "./HomePTopSlides";
import MainFavs from "./mainFavs";
import MainNowOpen from "./mainNowOpen";
import MainOnlines from "./mainOnlines";
import MainRest from "./mainRests";
import MainTableReservs from "./MainTableReservs";
import SearchComp2 from "../../DTechComp/Search2";
import { Helmet } from "react-helmet";

const HomePage = (props) => {




  const [order, setOrder] = useContext(OrderContext);

  const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);

  // const [theData] = useState(TheSrc());
  const [master, setMaster] = useContext(MasterContext);
  const [currentPage, setCurrentPage] = useContext(CurrentPContext);
  const [mainRests, setMainRests] = useState([]);


  const [mainRestCo, setmainRestCo] = useState();
  const [populars, setPopulars] = useState();

  const [mainRestCo2, setmainRestCo2] = useState();
  const [mainRestCo3, setmainRestCo3] = useState();
  const [mainRestCo4, setmainRestCo4] = useState();
  const [headers, setHeaders] = useState({
    TheFavRestsHeader: "",
    onlineRestsHeader: "",
    PopularsHeader: "",
    TableOrdersHeader: ""
  });


  const [faveRestCo, setFaveRestCo] = useState();
  const [onlineRestCo, setOnlineRestCo] = useState();

  const [onlineTableOrders, setOnlineTableOrders] = useState();

  const [nowOpen, setNowOpen] = useState();
  const [hPTopSlides, setHPTopSlides] = useState();

  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [bgImgPos, setBgImgPos] = useState("");

  const [nowOpenCount, setNowOpenCount] = useState(true);

  const [waitImgItm, setWaitImgItm] = useState({});
  const [googleSchem, setGoogleSchem] = useState();

  useEffect(() => {

    if (currentPage != null) {
      creatGoogleSchema()
    }
    props.scrollToTop();
    //מנקה את ההזמנה 
    setOrder(OrderFunctions.clearOrd());

    setWaitImgItm(myFunctions.setTheImgWait(currentPage, master));

    props.shoTopStrp(true);
    setTheMains();
    getHompData();
    setLoading(false);

  }, [])

  useEffect(() => {

    if (currentPage) {
      creatGoogleSchema()
    }

  }, [currentPage])


  const creatGoogleSchema = () => {

    let theSchem =
    {
      "@context": "https://schema.org/",
      "@type": "WebPage",
      "name": currentPage.pageName,
      "description": currentPage.sEOdescription,
      "url": ConnUrls.siteDomainNam,
      "image": ConnUrls.servMediasUrl + master.webSiteLogo,
      "publisher": {
        "@type": "Organization",
        "name": "AshdodMenu",
        "logo": {
          "@type": "ImageObject",
          "url": ConnUrls.servMediasUrl + master.webSiteLogo,
        }
      },
      "additionalProperty": {
        "@type": "PropertyValue",
        "name": "About",
        "value": currentPage.secondHead
      }

    }

    setGoogleSchem(JSON.stringify(theSchem))

  }



  const setTheMains = async () => {
    let go = await utilsRests.getMainRests();

    let sufft = [];
    sufft = myFunctions.setSHuffledRandom([...go.filter(itm => !itm.random)], sufft)
    let lstGo = [...go.filter(itm => itm.random), ...sufft];
    let readylist = myFunctions.getShufBuissClosAtLast(lstGo)

    setMainRests(readylist)
  }

  const getHompData = async () => {

    let hPage = await hPageUtils.getHomePage();
    setData(hPage)
  }

  const setData = (hPage) => {
    setCurrentPage({ ...hPage, pageTYp: "" });
    setWaitImgItm(myFunctions.setTheImgWait(hPage, master));

    setHPTopSlides(<HomePTopSlides topImages={hPage.topImages} />)

    setFaveRestCo(<MainFavs TheList={hPage.TheFavRests} />)

    // setOnlineRestCo(<MainOnlines TheList={hPage.onlineRests} />)

    setOnlineTableOrders(<MainTableReservs />)

    setNowOpen(<MainNowOpen noOpenRests={(trFls) => setNowOpenCount(trFls)} />)

    setPopulars(<MainFavs TheList={hPage.Populars} />)

    setHeaders({
      TheFavRestsHeader: hPage.TheFavRestsHeader || "האהובות בעיר",
      onlineRestsHeader: hPage.onlineRestsHeader || "זמינות עכשיו",
      PopularsHeader: hPage.PopularsHeader || "מנות פופלאריות",
      TableOrdersHeader: hPage.TableOrdersHeader || "הזמנות שולחן",
    })

  }

  const serachBuis = (txtSearch) => {
    history.push("/search?txSrch=" + txtSearch);
  }

  return (
    <div className="inerPage Hpage">

      <div className={loading ? "imgWaiting" :
        `imgWaiting animate__animated animate__fast animate__zoomOut ${waitImgItm.imgDelay}`}
        style={{
          backgroundImage: `url(${ConnUrls.servMediasUrl}${waitImgItm.waitImg})`,
          backgroundPosition: `${waitImgItm.imgBackPos}`,
          backgroundSize: `${(!mobiOrNot) ? "25%" : waitImgItm.backgroundSize}`,
          backgroundRepeat: `${waitImgItm.backgroundRepeat}`
        }}>

      </div>
      {/* <div className={loading ? "imgWaiting" : `imgWaiting animate__animated animate__fast animate__zoomOut ${currentPage.imgDelay ? currentPage.imgDelay : master.imgDelay}`}>
        <img src={`${ConnUrls.servMediasUrl}${master.waitingImgMaster}`} />
      </div> */}
      {loading ? "" :
        <div className={"Hpage"}>

          {!mobiOrNot ?
            <div className="headeronDeskTop">
              <div className={"onRight"}>
                <div className={"spaceRightLong"}>
                  <h1 className={"anakMeod bold MyFont"}>{currentPage.mainHead}</h1>
                </div>
                <div className={"spaceRightLong"}>
                  <h2 className={"regular grayText"}>{currentPage.secondHead}</h2>
                </div>
              </div>
              <div className={"onLeft"}>
                <div className={"srchDskTop"}>
                  <SearchComp2 pHolder={"חפש שם מסעדה, מנה או פריט"} srchFunc={(txtSrch) => serachBuis(txtSrch)} />
                </div>
              </div>
            </div> :
            <div className={"mobiTopSpacer"}></div>}

          {hPTopSlides}
          <div className={"detailsDiv"} loading={"lazy"}>
            {mobiOrNot ?
              <div className={"mastSec firstMastSec"}>
                <SearchComp2 pHolder={"חפש שם מסעדה, מנה או פריט"} srchFunc={(txtSrch) => serachBuis(txtSrch)} />
              </div>
              : ""}
            <div className={"spaceRightLong MbigFont bold SecondFont"}>
              קטגוריות
            </div>
            <div className={mobiOrNot ? "mastSec" : ""}>
              <CatsLnksComp />
            </div>
            {!mobiOrNot ? <><br /><br /></> : <br />}

            {(mobiOrNot ?
              <MainRest mainRests={mainRests} fromitem={"0"} toitem={"1"} /> :
              <MainRest mainRests={mainRests} fromitem={"0"} toitem={"3"} />)
            }

            {!mobiOrNot ?
              <>
                <br /><br />
                <MainRest mainRests={mainRests} fromitem={"4"} toitem={"7"} />
              </> : <br />}

            {(nowOpenCount) ?
              <>
                <div className={"spaceRightLong MbigFont bold SecondFont"}>
                  {headers.onlineRestsHeader}
                </div>
                {nowOpen}

                {!mobiOrNot ? <><br /></> : <br />}
              </>
              : ""}

            {
              (mobiOrNot ?
                <MainRest mainRests={mainRests} fromitem={"2"} toitem={"3"} /> : "")
            }


            <div className={" divHead MbigFont bold SecondFont"}>
              {headers.TheFavRestsHeader}
            </div>
            {faveRestCo}
            {!mobiOrNot ? <><br /></> : <br />}

            {
              (mobiOrNot ?
                <>
                  <MainRest mainRests={mainRests} fromitem={"4"} toitem={"5"} /><br /></> : "")
            }

            <div className={"setTheTop divHead MbigFont bold SecondFont"}>
              {headers.TableOrdersHeader}
            </div>
            {onlineTableOrders}

            {!mobiOrNot ? <><br /><br /></> : <br />}

            {(mobiOrNot ?
              <MainRest mainRests={mainRests} fromitem={"6"} toitem={"7"} /> : "")}

            <div className={"spaceRightLong MbigFont bold SecondFont"}>
              {headers.PopularsHeader}
            </div>
            {populars}

            {!mobiOrNot ? <><br /><br /></> : <br />}

            {
              (mobiOrNot ?
                <MainRest mainRests={mainRests} fromitem={"8"} toitem={"9"} /> : "")}
          </div>
        </div>
      }

      <Helmet
        meta={[
          {
            property: "og:url", content: `${ConnUrls.siteDomainNam}`
          }
        ]}>

        <link rel="canonical" href={`${ConnUrls.siteDomainNam}`} />
        <script className='structured-data-list' type="application/ld+json">{googleSchem}</script>

      </Helmet>
     
    </div>
  );
}

export default HomePage;
