import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Link } from "react-router-dom";
import ConnUrls from "../../Connections/ConnectURL";
import utilsRests from "../../_Utils/utilsRests";
import { useParams, useRouteMatch } from "react-router-dom";
import { ArrowCircleDownSharp, ArrowCircleRightSharp, ArrowCircleUp, FiberManualRecord } from "@mui/icons-material";
import MyDateTime from "../../DTechComp/MyDateTime";
import { MasterContext, MobiOrNotContext, UserContext } from "../../ContextApi/context";
import myFunctions from "../../myFunctions";
import ScrollingSaver from "../../DTechComp/ScrollingSaver";
import SearchComp from "../../DTechComp/Search";
import PopUpClearComp from "../../DTechComp/PopUpClearComp";
import UnregisterForm from "./UnregisterForm";
import utilsCodes from "../../_Utils/utilsCodes";
import PopUpRegular from "../../DTechComp/PopUpRegular";
import PopUpMsg from "../../DTechComp/PopUpMsg";
import { Helmet } from "react-helmet";



const Unsubscribe_Page = (props) => {



    const [master, setMaster] = useContext(MasterContext);
    const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);
    const [user, setUser, UserEffectFunction] = useContext(UserContext);

    const [loading, setLoading] = useState(true);
    const [thePopUp, setThePopUp] = useState("");

    const [waitImgItm, setWaitImgItm] = useState({});
    const history = useHistory();

    let popTiming = -1;


    useEffect(() => {
        // props.scrollToTop();
        setWaitImgItm(myFunctions.setTheImgWait({}, master));
        props.scrollToTop();
        props.shoTopStrp(false)
        props.showfotter(true)
        props.setShowbanners(false)

        setLoading(false)
    }, [])

    const unRegisterMe = () => {
        setThePopUp(firstPopUp())
    }


    const checkUnregCode = async (phoneNum) => {

        popTiming++;

        let codeReply = await utilsCodes.createCodeSms(phoneNum);

        if (codeReply.errormsg !== undefined
            && codeReply.errormsg != null
            && codeReply.errormsg != "") {
            setThePopUp(firstPopUp())
        }
        else {
            setThePopUp(secondPopUp(codeReply.nId, phoneNum, ""))
        }

    }
    const checkForSMSConCode = async (codeCheck, nId, phoneNum) => {

        let connectMe = await utilsCodes.checkUnregCode(nId, { codeCheck, phoneNum });

        if (connectMe.errMsg === "Removed") {
            removeUserMsg();
        }
        // (connectMe.errMsg != "" && connectMe.errMsg != undefined ) 
        else {

            setThePopUp(secondPopUp(nId, phoneNum, connectMe.errMsg))
        }
    }


    const firstPopUp = () => {
        popTiming++;

        return (<UnregisterForm
            Title={["הסרה מהמהערכת באמצעות", " SMS "]}
            ContentText={["נא להזין מספר טלפון"]} inLabel={""}
            okClick={(phoneNum) => checkUnregCode(phoneNum)}
            cancelClick={() => setThePopUp()}
            recreateCode={() => unRegisterMe()}
            errorMsg={"יש להזין מספר טלפון תקין"}
        />

        )
    }

    const secondPopUp = (nId, phoneNum, errorMsg) => {
        popTiming++;

        return (
            <PopUpRegular key={popTiming} Title={["הסרת רישום באמצעות", "SMS"]}
                ContentText={["ברגעים אלו נשלח אליך קוד לטלפון", "נא להקיש את הקוד"]} inLabel={""}
                okClick={(codeCheck) => checkForSMSConCode(codeCheck, nId, phoneNum)}
                cancelClick={() => setThePopUp()}
                recreateCode={() => unRegisterMe()}
                errorMsg={errorMsg}
            />)

    }


    const removeUserMsg = () => {
        popTiming++;
        setThePopUp(
            <PopUpMsg key={popTiming} Title={["הסרת המשתמש בוצעה בהצלחה"]}
            nosnippet={true}
                ContentText={[` נשמח לראותכם שוב.`]} inLabel={""}
                okClick={() => { setUser(); history.push('/') }}
                errorMsg={""} />
        );
    }

    return (

        // <div className={loading ? "imgWaiting" : `imgWaiting animate__animated animate__fast animate__zoomOut ${master.imgDelay}`}>
        //     <img src={`${ConnUrls.servMediasUrl}${master.waitingImgMaster}`} />
        // </div>
        <div className="UnsubscribePage">

            <div className={loading ? "imgWaiting" :
                `imgWaiting animate__animated animate__fast animate__zoomOut ${waitImgItm.imgDelay}`}
                style={{
                    backgroundImage: `url(${ConnUrls.servMediasUrl}${waitImgItm.waitImg})`,
                    backgroundPosition: `${waitImgItm.imgBackPos}`,
                    backgroundSize: `${(!mobiOrNot) ? "25%" : waitImgItm.backgroundSize}`,
                    backgroundRepeat: `${waitImgItm.backgroundRepeat}`
                }}>

            </div>

            {loading ? "" : <>
                {(mobiOrNot) ?
                    <div className={"headOnFixC SecondFont bold big"}>
                        הסרת רישום
                        <div className={`backSd`}>
                            <ArrowCircleRightSharp onClick={() => history.push('/')} />
                        </div>
                    </div> :
                    ""}
                <br /><br /><br /><br />

                {(!mobiOrNot) ?
                    <div>
                        <br /><br />
                        <p className="bold">הסרת רישום ממערכת אשדוד בתפריט</p><br />
                    </div>

                    : ""}
                <div className="wrapUnsubscribePage">
                    <p>לצערנו, בחרתם למחוק את פרטי המשתמש שלכם מהמערכת שלנו.</p><br />
                    <p className="bold">במידה והגעתם לכאן בטעות, פשוט סגרו את החלון או לחצו <span className="pointing underLineMe" onClick={() => history.push('/')}> כאן</span></p><br />
                    <p>- על מנת לבצע את המחיקה של הפרטים, יש להזין את מספר הטלפון עימו נרשמתם אצלנו במערכת.</p><br />
                    <p>- לאחר לחיצה על אישור, תשלח אליכם הודעת SMS עם קוד אישור שנדע שזה באמת אתם.</p><br />
                    <p>- עם קבלת הקוד והזנתו בחלון המיועד, נמחק את כל פרטיכם האישיים מהמערכת.</p><br />
                    <p>- <span className="bold">שימו לב, </span>במידה ותרצו שוב להשתמש במערכת שלנו, תאלצו לבצע רישום מחדש.</p><br />
                    <p>נשמח לשרתכם שוב.</p>
                    <div style={{ width: "200px" }}>
                        <br />
                        <button type="button" className="centerMe btn btn-success btnPopRegular yelowBtn pointing"
                            onClick={() => unRegisterMe()}>
                            אני רוצה להסיר את הרישום</button>
                    </div>
                </div>


                {thePopUp}

            </>


            }

            <Helmet
                meta={[
                    {
                        property: "og:url", content: `${ConnUrls.siteDomainNam}/unsubscribe}`
                    }
                ]}>
                <link rel="canonical" href={`${ConnUrls.siteDomainNam}/unsubscribe`} />
            </Helmet>
        </div >
    )
}




export default Unsubscribe_Page;