import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import ConnUrls from '../../Connections/ConnectURL';
import { MasterContext, OrderContext } from '../../ContextApi/context';
import OrderFunctions from '../../OrderSettings/OrderFunctions';
import BigPopUpMsg from '../BigPopUpMsg';
import PopUpMsg from '../PopUpMsg';



const TAKE_Confirmd = (props) => {
    const [master] = useContext(MasterContext);
    const [order, setOrder] = useContext(OrderContext);

    useEffect(() => {

    }, [])

    return (
        <PopUpMsg Title={["ההזמנה בוצעה"]}
        nosnippet={true}
            ContentText={[{ txt:"המסעדה מתחילה להכין את ההזמנה.",css:""}
                , { txt:`ניתן יהיה להגיע לאסוף בערך בעוד ${props.order.OrderTimes} דקות`,css:""}
                ,{ txt:`מספר הזמנה: ${props.order.orderNUM}`,css:""}
                ,{ txt: props.order.transactionId != "" && props.order.transactionId != undefined ?
                    "מספר אישור עסקה: " + props.order.transactionId : "חיוב לא בוצע",css:""}
            ]}
            inLabel={""}
            BottomTitle={["בתאבון!"]}
            topImg={`${ConnUrls.servMediasUrl}${master.orderApprovImg}`}
            cssStyl={"approveMsgPopup"}
            okClick={() => { setOrder(OrderFunctions.clearOrd()); props.okClick() }}
            errorMsg={""} />
    )
}




export default TAKE_Confirmd