import { ArrowCircleRightSharp, ArrowDropDown, ArrowDropUp, CheckCircleOutline, LocalConvenienceStoreOutlined } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { io } from 'socket.io-client';
import ConnUrls from "../../../Connections/ConnectURL";
import { CurrentPContext, MasterContext, OrderContext, UserContext } from "../../../ContextApi/context";
import BigPopUpMsg from "../../../DTechComp/BigPopUpMsg";
import MyDateTime from "../../../DTechComp/MyDateTime";
import No_Deliverys from "../../../DTechComp/Pop_Fixed/No_Deliverys";
import No_TakeAway from "../../../DTechComp/Pop_Fixed/No_TakeAway";
import Out_Of_Hours from "../../../DTechComp/Pop_Fixed/Out_Of_Hours";
import utilsOrders from "../../../_Utils/utilsOrders";
import utilsRests from "../../../_Utils/utilsRests";
import utilsUsers from "../../../_Utils/utilsUsers";
import ShowMyOrder from "./showMyOrder";
import PopUpMsg from "../../../DTechComp/PopUpMsg";
import TopBigSlides from "../../../DTechComp/TopBigSlides";
import { Helmet } from "react-helmet";
import MyOrderDisp from "./MyOpenOrderDisp";
import MyOpenOrderDisp from "./MyOpenOrderDisp";
import MyClosedOrderDisp from "./MyClosedOrderDisp";
import { Button } from "@mui/material";

const mySocket = io.connect(ConnUrls.socketClient, {
    auth: {
        token: localStorage.getItem("usrtoken")
    },
    query: {
        userType: "client"
    }
}
)

const MyOrders = (props) => {




    const [master] = useContext(MasterContext);
    const [user] = useContext(UserContext);

    const [order, setOrder] = useContext(OrderContext);
    const [currentPage, setCurrentPage] = useContext(CurrentPContext);


    const [timers, setTimers] = useState([]);
    const [allOrders, setAllOrders] = useState([]);
    const [closed, setClosed] = useState([]);
    const [opens, setOpens] = useState([]);
    const [maxCLosedToSHow, setMaxCLosedToSHow] = useState(10);
    const [firstHead, steFirstHead] = useState("מחפש הזמנות")

    const [thePopUp, setThePopUp] = useState("");

    const [intervalID, setIntervalID] = useState();

    const history = useHistory();

    // const [topSlides, setTopSlides] = useState();


    useEffect(() => {
        mySocket.on("orderUpdated", (data) => {
            getAndSetAllAOrders();
        })

    }, [mySocket])

    useEffect(() => {
        getAndSetAllAOrders();
    }, [])

    const getAndSetAllAOrders = async () => {

        if (user != null && user._id != null) {
            props.scrollToTop();
            props.shoTopStrp(false)
            props.showfotter(true)
            props.setShowbanners(false)

            let getIt = await utilsUsers.getUsersOrders(user._id)

            if (!getIt.length > 0) { steFirstHead("לא נמצאו הזמנות") };

            setClosedOpens(getIt)
            setAllOrders(getIt);
            getTimes(getIt)
        }
        else {
            history.push("./")
        }
    }

    const stopHandler = () => {
        var highestTimeoutId = setInterval(";");

        for (var i = 0; i < highestTimeoutId; i++) {
            clearInterval(i);
        }

    }

    const getTimes = (OrdrsList) => {

        stopHandler()
        startHandler(OrdrsList)
    }

    const startHandler = (OrdrsList) => {


        setIntervalID(setInterval(() => {

            OrdrsList.forEach((item, i) => {

                let theId = item.ord.orderNUM;
                var theELEMENT = window.document.getElementById(theId);
                var theELEMENT_GR = window.document.getElementById(theId + "_GR");

                var theELEMENTConf = window.document.getElementById(`Conf_${theId}`);
                if (theELEMENT) {

                    if (item.ord.orderStatus != "סגור" && item.ord.orderStatus != "מושהה" && item.ord.orderStatus != "מבוטל") {

                        let theItm = MyDateTime.getTimeLeftTilThen(item.ord.dateCreated, item.ord.OrderTimes);
                        if (theItm.theTm.split(":").length < 2) {
                            theItm.theTm = "00:" + theItm.theTm;
                        }
                        if (!theItm.theEnd) {
                            theELEMENT.innerText = theItm.theTm

                            //עברו עשר דקות לאחר מועד ההזמנה , על מנת להציג את כפתור אישור קבלה
                            let Min10theItm = MyDateTime.getTimeLeftTilThen(item.ord.dateCreated, 10);

                            if (Min10theItm.theEnd) {
                                theELEMENTConf.classList.remove("hideMe")
                            }

                        }
                        else {
                            theELEMENT_GR.classList.add("hideMe");
                            theELEMENT.classList.add("hideMe");

                            var endElem = window.document.getElementById(`${theId}_Ended`);
                            endElem.classList.remove("hideMe");

                            theELEMENTConf.classList.remove("hideMe")

                        }

                    }
                    else {
                        theELEMENT.innerText = item.ord.orderStatus;
                        theELEMENT_GR.innerText = "סטטוס "
                        theELEMENTConf.classList.remove("hideMe")
                    }
                    // theELEMENTConf.style.visibility = (theELEMENT.innerText == "00:00") ?
                    //     "" : "hidden";



                }
            });

        }, 1000));
    }


    let popTiming = -1;
    const duplicThisOrder = async (ordId) => {
        let ordtotdop = await utilsOrders.getDuplictedOrder(ordId);

        // let ordDup = allOrders.find(or => or.ord._id === ordId)
        // ordDup.ord.orderNUM = "";
        // ordDup.ord._id = null;
        // ordDup.ord.creditCrdApprov = "";
        // ordDup.ord.orderStatus = "ממתין לתגובה";
        // ordDup.ord.orderKopon = {};

        // ordDup.ord.Orders = ordDup.ord.Orders.filter(ordItm => ordItm.ordProdID !== "KOPON");
        // if (chckingKopon != -1) {
        //     ordDup.ord.Orders.splice(chckingKopon, 1)
        // }

        let restPage = await utilsRests.getRestById(ordtotdop.restId);

        if (restPage === null) {
            <PopUpMsg key={popTiming}
                nosnippet={true}
                Title={["אופססס!!!"]}
                cssStyl={"okPOP"}
                topSlides={<TopBigSlides TopImages={[{ img: master.oopsPopImg, txt: "" }]} />}
                ContentText={["עסק זה כרגע לא פעיל",
                    "להזמנות באתר"]}
                inLabel={""}
                BtnTxt={"חזור"}
                okClick={() => setThePopUp()}
                btn1Css={"btnGreen bold btnPoppadding15"}
                errorMsg={""} />

        }
        else {
            let doDeliver = restPage.Delivery.Doing;
            let doTakeAway = restPage.TakeAway.Doing;

            //מחוץ לשעות הפעילות
            if (!MyDateTime.checkIfBetweenHours(restPage).openOrCLose) {

                popTiming++;

                setThePopUp(
                    <Out_Of_Hours
                        okClick={() => { setThePopUp() }}
                        btn2Click={() => {
                            history.push(`/restaurants/${restPage.pageUrlFix != null &&
                                restPage.pageUrlFix != "" ? restPage.pageUrlFix : restPage._id
                                }`)
                        }}
                    />
                )


            }
            else {
                //לא מקבל משלוחים
                if (ordtotdop.OrderType == "Deliver" && !doDeliver) {
                    setThePopUp(
                        <No_Deliverys
                            okClick={() => { setThePopUp() }}
                            btnClick2={() => history.push(`/restaurants/${restPage.pageUrlFix != null &&
                                restPage.pageUrlFix != "" ? restPage.pageUrlFix : restPage._id
                                }`)}
                        />
                    )
                }
                //לא מקבל Take
                else if (ordtotdop.OrderType == "Take" && !doTakeAway) {
                    setThePopUp(
                        <No_TakeAway
                            okClick={() => setThePopUp()}
                            btnClick2={() => history.push(`/restaurants/${restPage.pageUrlFix != null &&
                                restPage.pageUrlFix != "" ? restPage.pageUrlFix : restPage._id
                                }`)}
                        />
                    )
                }

                //הכל טוב, משכפל את הזמנה
                else {
                    setCurrentPage({ ...restPage, pageTYp: "withConPay" });
                    // order = ordDup.ord;
                    setOrder(ordtotdop)

                    history.push("/MyOrder");
                }

            }
        }
    }

    const showThisOrder = (orderDivId) => {
        var ordDisp = window.document.getElementById(orderDivId);

        if (!ordDisp.classList.contains("showMe")) {
            ordDisp.classList.add("showMe");
            ordDisp.classList.remove("hideMe");

        } else {
            ordDisp.classList.add("hideMe");
            ordDisp.classList.remove("showMe");
        }
    }


    const orderConfirmd = (ord) => {

        setThePopUp(

            <PopUpMsg key={popTiming}
                nosnippet={true}
                Title={["אישור קבלה"]}
                cssStyl={"okPOP centerPop mediumSizePop"}
                topSlides={<TopBigSlides TopImages={[{ img: master.orderApprovImg, txt: "" }]} />}
                ContentText={["בלחיצה על אישור", "נדע שקיבלת את ההזמנה"]}
                inLabel={""}
                okClick={() => aprovedOrderRecive(ord)}
                BtnTxt={"אישור"}
                btn1Css={"btnGreen bold btnPoppadding15"}
                btn2Css={"btnRed bold btnPoppadding15"}
                BtnTxt2={"חזור"}
                btnClick2={() => setThePopUp()}
                errorMsg={""} />
        )
    }


    const aprovedOrderRecive = async (ordr) => {

        await utilsOrders.updateStatus(ordr._id);
        setThePopUp();
        setClosedOpens(allOrders);

    }

    const goToBack = () => {
        const previousState = history.state;
        if (previousState != "/MyOrder" && previousState != null) {
            history.goBack();
        }
        else {
            history.push("/")
        }

    }

    const setClosedOpens = (OrdrsList) => {

        let open = []
        let close = []

        OrdrsList.forEach((ord, indx) => {

            let inord = ord.ord;

            if (inord.orderStatus != "סגור" &&
                inord.orderStatus != "מושהה" &&
                inord.orderStatus != "מבוטל") {

                open.push(ord)
            }
            else {
                close.push(ord)
            }

        })

        setOpens(open.reverse());
        setClosed(close.reverse());


    }

    const ShowAllCLosed = () => {
        setMaxCLosedToSHow(maxCLosedToSHow != Infinity ? Infinity : 10)
    }

    let countit = -1;

    return (
        <div className={"MyOrdersWrap"}>
            <div className={"headOnFixC SecondFont bold big"}>
                ההזמנות שלי
                <div className={`backSd`}>
                    <ArrowCircleRightSharp onClick={() => goToBack()} />
                </div>
            </div>
            <br /><br /><br /><br />
          
          
            <div className={"col-12 fullRow spaceRightLong overflowHid big bold"}>
                {(user?._id != null && user?.firstName != "null" && user?.firstName != "") &&
                    <span>{user.firstName}  {user?.lastName}</span>}

            </div>

            {(opens.length > 0) ?
                <>
                    <div className={"fullRow overflowHid"}>
                        <div className={"insideFrame"}>
                            {
                                opens.findIndex(ord => ord.ord.paymentStatus !== "ממתין לחיוב") !== -1 &&
                                <div div className={"spaceRightLong MbigFont bold SecondFont"}>הזמנות פעילות</div>
                            }

                            <div className={"MyOrdersLst"}>
                                <div className={"insideFrame"}>
                                    {
                                        opens.map((ord, indx) => {
                                            countit++;
                                            if (ord.ord.paymentStatus !== "ממתין לחיוב") {
                                                return (
                                                    <MyOpenOrderDisp key={indx} theOrder={ord}
                                                        ordrIndex={indx}
                                                        orderConfirmd={(ordBack) => orderConfirmd(ordBack)}
                                                        showThisOrder={(orderDivId) => showThisOrder(orderDivId)}
                                                    />
                                                )
                                            }
                                        })

                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </>
                : ""}
            {
                (closed.length > 0) ? <>
                    <div className={"fullRow overflowHid"}>
                        <div className={"insideFrame"}>
                            <div className={"spaceRightLong MbigFont bold SecondFont onRight"}>היסטוריית הזמנות</div>

                            <div className={"onLeft pointing"} >
                                <Button className={"myBtnWithIcon"} variant="outlined"
                                    startIcon={maxCLosedToSHow != Infinity ? <ArrowDropDown /> : <ArrowDropUp />}
                                    onClick={() => ShowAllCLosed()}
                                >
                                    <span className={"btnWithIconTxt"}>
                                        {maxCLosedToSHow != Infinity ? "הצג הכל" : "הצג אחרונים"}
                                    </span>
                                </Button>
                            </div>
                        </div>

                    </div>
                    <div className={"MyOrdersLst historys"}>

                        <div className={"insideFrame"}>

                            {
                                closed.map((ord, indx) => {

                                    countit++;
                                    if (indx < maxCLosedToSHow) {
                                        return (

                                            <MyClosedOrderDisp theOrder={ord}
                                                key={indx}
                                                ordrIndex={indx}
                                                orderConfirmd={(ordBack) => orderConfirmd(ordBack)}
                                                showThisOrder={(orderDivId) => showThisOrder(orderDivId)}
                                                duplicThisOrder={(ordId) => duplicThisOrder(ordId)}
                                            />


                                        )
                                    }
                                    else {
                                    }
                                })
                            }
                        </div>

                    </div>
                </>
                    : <h3> <center>{firstHead}</center></h3>
            }
            {thePopUp}
            <Helmet
                meta={[
                    {
                        property: "og:url", content: `${ConnUrls.siteDomainNam}/MyOrders`
                    }
                ]}>
                <link rel="canonical" href={`${ConnUrls.siteDomainNam}/MyOrders`} />
            </Helmet>

        </div >
    )

}

export default MyOrders